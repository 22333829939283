import React, { useState, useEffect } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import fetcher from '@services/fetcher';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import Card from '@components/card';
import Loading from '@components/loading';

import { useAuthContext } from '@contexts/auth';

import {
  Plus, XCircle, Package, Bell,
} from 'react-feather';

// import styles from './style';

import Row from './row';
import FormCategory from './form';

function Categories() {
  const navigate = useNavigate();

  const { mutate } = useSWRConfig();
  const { data: categories, error } = useSWR(
    'https://us-central1-clube-do-leilao.cloudfunctions.net/categories-index?withTotal=true',
    fetcher,
  );

  const [authState] = useAuthContext();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [subOf, setSubOf] = useState(null);

  const [canEdit, setCanEdit] = useState(null);

  useEffect(() => {
    if (authState.user?.companyId) navigate('/');
    else if (!authState.user?.owner && !authState.user?.permissions?.categories?.read) navigate('/');
    else setCanEdit(authState.user?.owner || authState.user?.permissions?.categories?.edit);
  }, [authState]);

  const onClose = () => {
    setSubOf(null);
    setSelectedCategory(null);
    setOpenDrawer(false);
    mutate('https://us-central1-clube-do-leilao.cloudfunctions.net/categories-index?withTotal=true');
  };

  return (
    <Grid
      container
      wrap="nowrap"
      justifyContent="center"
      alignItems="center"
    >
      <Card>
        <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 3 }}>
          <Typography color="primary" variant="h4">
            CATEGORIAS
          </Typography>
          {canEdit && (
            <Button size="large" variant="contained" color="primary" onClick={() => setOpenDrawer(true)}>
              <Plus style={{ marginRight: 8 }} />
              Adicionar categoria
            </Button>
          )}
        </Grid>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: 34 }} />
                <TableCell sx={{ fontWeight: 800, color: 'secondary.main' }}>
                  Nome
                </TableCell>
                <TableCell align="center" sx={{ width: 110, fontWeight: 800, color: 'secondary.main' }}>
                  <Package />
                </TableCell>
                <TableCell align="center" sx={{ width: 110, fontWeight: 800, color: 'secondary.main' }}>
                  <Bell />
                </TableCell>
                {canEdit && (
                  <TableCell style={{ width: 108 }} />
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!error && !categories && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Loading height={300} />
                  </TableCell>
                </TableRow>
              )}
              {!error && categories?.filter((c) => !c.subCategoryOf).map((row) => (
                <Row
                  key={row.idCategory}
                  row={row}
                  categories={categories}
                  onEdit={(data) => {
                    setSelectedCategory(data);
                    if (data.subCategoryOf) setSubOf(data.subCategoryOf);
                    setOpenDrawer(true);
                  }}
                  onAdd={(id) => {
                    setSubOf(id);
                    setOpenDrawer(true);
                  }}
                  canEdit={canEdit}
                />
              ))}
              {error && (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      wrap="nowrap"
                      sx={{ padding: 4 }}
                    >
                      <XCircle size={58} color="#f44336" style={{ strokeWidth: 1, marginRight: 23 }} />
                      <Typography color="textSecondary" fontSize={18} fontWeight={200}>
                        Ocorreu algum erro com as categorias! Por favor, entre em contato com o
                        suporte
                      </Typography>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <FormCategory
        open={!!openDrawer}
        subCategoryOf={subOf}
        category={selectedCategory}
        categories={categories || []}
        onClose={onClose}
      />
    </Grid>
  );
}

export default Categories;
