export default {
  card: {
    padding: 4,
  },
  title: (theme) => ({
    color: theme.palette.secondary.main,
    fontSize: 22,
    fontWeight: 600,
    mb: 4,
  }),
  subtitleColor: (color) => ({
    background: color,
    width: 14,
    height: 14,
    borderRadius: 11,
    marginRight: 8,
  }),
  subtitleText: {
    mr: 3,
    fontSize: 14,
    color: 'white',
    opacity: 0.7,
  },
  selectOrderBy: {
    '& fieldset': {
      borderColor: 'primary.main',
    },
    '& > div > div': {
      padding: '12px 48px 12px 16px !important',
    },
  },
  cardList: {
    borderRadius: 1,
    border: '1px solid #222',
    padding: 2,
    mb: 1,
    color: '#aaa',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
};
