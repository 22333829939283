import fetcher from '@services/fetcher';
import useSWR from 'swr';
import { API_BASE_URL } from '@consts';

const useCities = () => {
  const { data: cities } = useSWR(
    `${API_BASE_URL}/cities-show`,
    fetcher,
  );

  return cities ?? [];
};

export default useCities;
