import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import styles from './style';

function DeleteDialog({
  open, title, onDelete, onClose,
}) {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    await onDelete();
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => !loading && onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: styles.dialog }}
    >
      <DialogTitle id="alert-dialog-title" sx={{ paddingTop: 3 }}>{title}</DialogTitle>
      <DialogContent sx={{ marginTop: 1 }}>
        <DialogContentText id="alert-dialog-description">
          Esta ação não poderá ser desfeita posteriomente. Tenha certeza do que está fazendo.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <LoadingButton loading={loading} variant="outlined" color="error" onClick={handleDelete}>
          EXCLUIR
        </LoadingButton>
        <Button disabled={loading} variant="contained" color="primary" onClick={onClose}>
          CANCELAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteDialog;
