import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button, CircularProgress, Grid, Menu, MenuItem, Typography,
} from '@mui/material';

import { useAuthContext } from '@contexts/auth';

import FormCategory from '@app/categories/form';

import { CornerDownRight } from 'react-feather';

import axios from 'axios';
import styles from './style';

function ChangeAuctionsCategories({
  auctions, categories, disabled, onClose,
}) {
  const [authState] = useAuthContext();

  const [loading, setLoading] = useState(false);

  const [openMenu, setOpenMenu] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(false);

  const changeCategories = useCallback(async () => {
    setLoading(true);
    await axios.post(
      'https://us-central1-clube-do-leilao.cloudfunctions.net/auctions-update',
      { category: selectedCategory, idsAuction: auctions.map((a) => a.idAuction) },
    );
    setSelectedCategory(false);
    setLoading(false);
    onClose();
  }, [auctions, selectedCategory]);

  useEffect(() => {
    if (selectedCategory && !loading && openMenu) {
      changeCategories();
    }
  }, [selectedCategory, openMenu, changeCategories]);

  if (openCategory) {
    return (
      <FormCategory
        open
        subCategoryOf={typeof openCategory !== 'boolean' ? openCategory : null}
        categories={categories}
        onClose={() => setOpenCategory(false)}
      />
    );
  }

  return (
    <>
      <Button
        size="large"
        variant="contained"
        color="primary"
        onClick={(e) => setOpenMenu(e.currentTarget)}
        sx={{ p: 2 }}
        disabled={disabled}
      >
        ALTERAR CATEGORIA DOS LEILÕES
      </Button>

      <Menu
        open={!!openMenu}
        anchorEl={openMenu}
        onClose={(e) => {
          e.stopPropagation();
          setOpenMenu(false);
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      >
        {loading && (
          <MenuItem disabled sx={{ padding: '12px 24px', width: openMenu.clientWidth }}>
            <Grid container wrap="nowrap" alignItems="center" justifyContent="center">
              <CircularProgress size={24} sx={{ marginRight: 2 }} />
              <Typography>
                Alterando os leilões
              </Typography>
            </Grid>
          </MenuItem>
        )}
        {!loading && !authState.user?.companyId && (
          <MenuItem
            onClick={() => setOpenCategory(true)}
            sx={styles.addItem}
            style={{ padding: '12px 24px 12px 58px' }}
          >
            Adicionar categoria
          </MenuItem>
        )}
        {!loading && categories?.filter((c) => !c.subCategoryOf).map((main) => [
          (
            <MenuItem
              key={main.idCategory}
              sx={{ padding: '12px 24px', fontWeight: 800 }}
              onClick={() => setSelectedCategory(main.idCategory)}
            >
              <Grid container alignItems="center">
                <img
                  src={main.icon}
                  alt={main.name}
                  style={{ width: 18, height: 18, marginRight: 16 }}
                />
                {main.name}
              </Grid>
            </MenuItem>
          ),
          categories?.filter((c) => c.subCategoryOf === main.idCategory).map((item) => (
            <MenuItem
              key={item.idCategory}
              sx={{ padding: '12px 24px' }}
              onClick={() => setSelectedCategory(item.idCategory)}
            >
              <Grid container alignItems="center">
                <CornerDownRight size={14} style={{ margin: '0 16px 0 4px', opacity: 0.5 }} />
                {item.name}
              </Grid>
            </MenuItem>
          )),
          !authState.user?.companyId && (
            <MenuItem
              onClick={() => setOpenCategory(main.idCategory)}
              sx={styles.addItem}
              style={{ padding: '12px 24px 12px 58px' }}
            >
              Adicionar sub-categoria de&nbsp;
              <b>{main.name}</b>
            </MenuItem>
          ),
        ])}
      </Menu>
    </>
  );
}

ChangeAuctionsCategories.propTypes = {
  auctions: PropTypes.arrayOf(PropTypes.number).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    idCategory: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    subCategoryOf: PropTypes.number.isRequired,
  })).isRequired,
  disabled: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

ChangeAuctionsCategories.defaultProps = {
  disabled: false,
};

export default ChangeAuctionsCategories;
