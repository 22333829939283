import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import fetcher from '@services/fetcher';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import { visuallyHidden } from '@mui/utils';

import Card from '@components/card';
import Loading from '@components/loading';

import { useAuthContext } from '@contexts/auth';

import { Plus, XCircle } from 'react-feather';

// import styles from './style';

import FormUser from './form';
import Row from './row';
import SearchInput from '../../components/search-input';

const headCells = [
  {
    id: 'name',
    numeric: false,
    label: 'Nome',
  },
  {
    id: 'email',
    numeric: false,
    label: 'E-mail',
  },
  {
    id: 'phone',
    numeric: false,
    label: 'Celular',
  },
  {
    id: 'status',
    numeric: false,
    label: 'Status',
  },
  {
    id: 'actions',
    numeric: false,
    label: '',
  },
];

function Companies() {
  const navigate = useNavigate();

  const [authState] = useAuthContext();

  const { data: users, error } = useSWR(
    'https://us-central1-clube-do-leilao.cloudfunctions.net/user-app-index',
    fetcher,
  );
  const [openDrawer, setOpenDrawer] = useState(false);

  const [search, setSearch] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('status');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [canEdit, setCanEdit] = useState(null);

  useEffect(() => {
    if (authState.user?.companyId) navigate('/');
    else if (!authState.user?.owner && !authState.user?.permissions?.usersApp?.read) navigate('/');
    else setCanEdit(authState.user?.owner || authState.user?.permissions?.usersApp?.edit);
  }, [authState]);

  const onClose = () => {
    setOpenDrawer(false);
  };

  const handleRequestSort = (property) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = users ? users
    .filter((user) => {
      const contain = (string) => string.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      return !search || contain(user.name) || contain(user.email);
    })
    .sort((a, b) => {
      if (orderBy === 'phone') {
        if (a.phone && b.phone) {
          if (order === 'asc') return a.phone < b.phone ? -1 : 1;
          return a.phone > b.phone ? -1 : 1;
        }
        if (order === 'asc') return !a.phone && b.phone ? -1 : 1;
        return a.phone && !b.phone ? -1 : 1;
      }
      if (orderBy === 'status') {
        if (order === 'asc') {
          if (!a.deletedAt && !b.deletedAt) return !a.trialEndAt && b.trialEndAt ? -1 : 1;
          return !a.deletedAt && b.deletedAt ? -1 : 1;
        }
        if (!a.deletedAt && !b.deletedAt) return a.trialEndAt && !b.trialEndAt ? -1 : 1;
        return a.deletedAt && !b.deletedAt ? -1 : 1;
      }
      if (order === 'asc') return a[orderBy]?.toLowerCase() < b[orderBy]?.toLowerCase() ? -1 : 1;
      return a[orderBy]?.toLowerCase() > b[orderBy]?.toLowerCase() ? -1 : 1;
    })
    : [];

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - filteredUsers.length);

  return (
    <Grid
      container
      wrap="nowrap"
      justifyContent="center"
      alignItems="center"
    >
      <Card>
        <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 3 }}>
          <Grid container direction="column">
            <Typography color="primary" variant="h4">
              USUÁRIOS DO APLICATIVO
            </Typography>
            {users && (
              <Typography color="secondary" fontSize={22} sx={{ mt: 1 }}>
                <b>{users.filter((u) => !u.trialEndAt).length}</b>
                {' assinantes de '}
                <b>{users.length}</b>
                {' usuários'}
              </Typography>
            )}
          </Grid>
          {canEdit && (
            <Grid container wrap="nowrap" sx={{ width: 'max-content' }}>
              <SearchInput
                label="Pesquise pelo nome ou e-mail"
                value={search}
                onChange={setSearch}
              />
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => setOpenDrawer(true)}
                sx={{ whiteSpace: 'nowrap' }}
              >
                <Plus style={{ marginRight: 8 }} />
                Adicionar usuário
              </Button>
            </Grid>
          )}
        </Grid>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: 'background.default' }}>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'center' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                    sx={{ color: 'secondary.main', fontWeight: 800, whiteSpace: 'nowrap' }}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={handleRequestSort(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!users && !error && (
                <TableRow>
                  <TableCell colSpan={10}>
                    <Loading height={300} />
                  </TableCell>
                </TableRow>
              )}
              {filteredUsers
                .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                .map((row) => (
                  <Row
                    key={row.idAuction}
                    user={row}
                    canEdit={canEdit}
                  />
                ))}
              {users && !error && emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={10} />
                </TableRow>
              )}
              {error && (
                <TableRow>
                  <TableCell colSpan={10}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      wrap="nowrap"
                      sx={{ padding: 4 }}
                    >
                      <XCircle size={58} color="#f44336" style={{ strokeWidth: 1, marginRight: 23 }} />
                      <Typography color="textSecondary" fontSize={18} fontWeight={200}>
                        Ocorreu algum erro com os leiloeiros! Por favor, entre em contato com o
                        suporte
                      </Typography>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          showFirstButton
          showLastButton
          labelRowsPerPage="Leilões por página"
          labelDisplayedRows={({
            from, to, count,
          }) => `${from}–${to} de ${count !== -1 ? count : `mais que ${to}`}`}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <FormUser open={openDrawer} onClose={onClose} />
    </Grid>
  );
}

export default Companies;
