import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Card from '@components/card';

import { useAuthContext } from '@contexts/auth';

import { MenuItem, TextField } from '@mui/material';

import UserChart from './user-chart';
import AuctionChart from './auction-chart';
import TopAuctions from './top-auctions';
import TopNotifications from './top-notifications';

const dateOptions = [
  { id: 'last-7-days', label: 'Últimos 7 dias' },
  { id: 'last-30-days', label: 'Últimos 30 dias' },
  { id: 'this-month', label: 'Este mês' },
  { id: 'last-month', label: 'Último mês' },
  { id: 'last-3-months', label: 'Últimos 3 meses' },
  { id: 'custom', label: 'Personalizado' },
];

function Companies() {
  const navigate = useNavigate();

  const [authState] = useAuthContext();

  const [dashInterval, setDashInterval] = useState('last-7-days');

  // const [canEdit, setCanEdit] = useState(null);

  useEffect(() => {
    if (authState.user?.companyId) navigate('/');
    else if (!authState.user?.owner && !authState.user?.permissions?.dashboard?.read) navigate('/');
    // else setCanEdit(authState.user?.owner || authState.user?.permissions?.dashboard?.edit);
  }, [authState]);

  // useEffect(() => {
  //   navigate('/users');
  // }, [navigate]);

  return (
    <Grid
      container
      wrap="nowrap"
      justifyContent="center"
      alignItems="center"
    >
      <Card>
        <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 3 }}>
          <Typography color="primary" variant="h4">
            DASHBOARD
          </Typography>
          <TextField
            select
            label="Período"
            value={dashInterval}
            onChange={(e) => setDashInterval(e.target.value)}
            sx={{ minWidth: 350 }}
          >
            {dateOptions.map((option) => (
              <MenuItem value={option.id} key={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid container sx={{ mt: 0 }}>
          <Grid item sm={12} md={6}>
            <UserChart />
          </Grid>
          <Grid item sm={12} md={6}>
            <AuctionChart />
          </Grid>
          <Grid item sm={12} md={6}>
            <TopAuctions />
          </Grid>
          <Grid item sm={12} md={6}>
            <TopNotifications />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}

export default Companies;
