import React from 'react';
import PropTypes from 'prop-types';

import Lottie from 'react-lottie';
import * as animationData from '@assets/loading.json';

function Loading({ height }) {
  return (
    <div style={{
      height: height || '100vh', display: 'flex', alignItems: 'center', justifyheight: 'center',
    }}
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        height={height || 400}
        width={height || 400}
      />
    </div>
  );
}

Loading.propTypes = {
  height: PropTypes.number,
};

Loading.defaultProps = {
  height: null,
};

export default Loading;
