import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { auth } from '@services/firebase';
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useAuthContext } from '@contexts/auth';

import { X, Eye, EyeOff } from 'react-feather';

function FormUser({ open, onClose }) {
  const [authState] = useAuthContext();

  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reNewPassword, setReNewPassword] = useState('');
  const [errors, setErrors] = useState({
    password: false, newPassword: false, reNewPassword: false,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    if (!loading) {
      setPassword('');
      setNewPassword('');
      setReNewPassword('');
      setErrors({ password: false, newPassword: false, reNewPassword: false });
      onClose();
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const errs = { password: false, newPassword: false, reNewPassword: false };

    if (!password) errs.password = 'Você precisa inserir sua senha atual';
    if (!newPassword) errs.newPassword = 'Você precisa crair uma nova senha';
    if (!reNewPassword) errs.reNewPassword = 'Você precisa confirmar sua nova senha';
    else if (newPassword !== reNewPassword) errs.reNewPassword = 'As senhas não correspondem';

    setErrors(errs);

    if (errs.password || errs.newPassword || errs.reNewPassword) {
      setLoading(false);
      return;
    }

    try {
      const credential = EmailAuthProvider.credential(authState.user.email, password);
      await reauthenticateWithCredential(auth.currentUser, credential);

      await updatePassword(auth.currentUser, newPassword);

      setLoading(false);
      handleClose();
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        setLoading(false);
        setErrors({ ...errs, password: 'Esta senha está incorreta' });
      }
    }
  };

  return (
    <Drawer
      anchor="right"
      open={!!open}
      onClose={handleClose}
      PaperProps={{ sx: { backgroundImage: 'none' } }}
    >
      <Grid container direction="column" sx={{ width: '500px', padding: 4 }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Typography fontWeight={800} fontSize={20}>
            Alterar senha
          </Typography>
          <IconButton onClick={handleClose}>
            <X />
          </IconButton>
        </Grid>
        <form onSubmit={submit}>
          <Grid container direction="column" spacing={4} sx={{ flexGrow: 1, padding: '32px 0' }}>
            <Grid item>
              <TextField
                error={!!errors.password}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label="Senha atual"
                value={password}
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                color="secondary"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <EyeOff /> : <Eye />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.password && (
                <FormHelperText error>{errors.password}</FormHelperText>
              )}
            </Grid>
            <Grid item>
              <TextField
                error={!!errors.newPassword}
                fullWidth
                type={showNewPassword ? 'text' : 'password'}
                label="Nova senha"
                value={newPassword}
                name="password"
                onChange={(e) => setNewPassword(e.target.value)}
                color="secondary"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowNewPassword(!showNewPassword)}>
                        {showNewPassword ? <EyeOff /> : <Eye />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.newPassword && (
                <FormHelperText error>{errors.newPassword}</FormHelperText>
              )}
            </Grid>
            <Grid item>
              <TextField
                error={!!errors.reNewPassword}
                fullWidth
                type="password"
                label="Confirme a nova senha"
                value={reNewPassword}
                name="password"
                onChange={(e) => setReNewPassword(e.target.value)}
                color="secondary"
              />
              {errors.reNewPassword && (
                <FormHelperText error>{errors.reNewPassword}</FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                disabled={loading}
                size="large"
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                type="submit"
                loading={loading}
                size="large"
                variant="contained"
                color="primary"
              >
                Alterar
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Drawer>
  );
}

FormUser.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default FormUser;
