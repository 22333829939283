import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSWRConfig } from 'swr';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';

import { ChevronDown, ChevronUp } from 'react-feather';
import axios from 'axios';

const styles = {
  mainCategory: {
    position: 'sticky',
    top: 0,
    backgroundColor: 'background.paper',
    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))',
    zIndex: 1,
    pb: 1,
  },
};

function CategoriesList({
  main, categories, selected, onSelect,
}) {
  const [open, setOpen] = useState(false);

  return (
    <Grid container direction="column" sx={{ mb: 2 }}>
      <Grid container wrap="nowrap" alignItems="center" sx={styles.mainCategory}>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
          sx={{ mr: 1 }}
        >
          {open ? <ChevronUp /> : <ChevronDown />}
        </IconButton>
        <Typography fontSize={18}>
          {main.name}
        </Typography>
      </Grid>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {categories.filter((c) => c.subCategoryOf === main.idCategory).map((category) => (
          <Grid container wrap="nowrap" alignItems="center">
            <Checkbox
              color="secondary"
              checked={selected?.idCategory === category.idCategory}
              onChange={(e) => (e.target.checked ? onSelect(category) : onSelect(null))}
            />
            <Typography>
              {category.name}
            </Typography>
          </Grid>
        ))}
        {categories.filter((c) => c.subCategoryOf === main.idCategory).length === 0 && (
          <Typography color="textSecondary" sx={{ ml: 5 }}>
            Nenhuma sub-categoria encontrada
          </Typography>
        )}
      </Collapse>
    </Grid>
  );
}

CategoriesList.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  main: PropTypes.shape({
    idCategory: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  selected: PropTypes.shape({
    idCategory: PropTypes.string,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
};

function MigrateDialog({
  categories, category, open, onClose,
}) {
  const { mutate } = useSWRConfig();

  const [loading, setLoading] = useState(false);
  const [toCategory, setToCategory] = useState(null);
  const [isDelete, setIsDelete] = useState(true);

  const handleConfirm = async () => {
    setLoading(true);

    await axios.post(
      'https://us-central1-clube-do-leilao.cloudfunctions.net/categories-migrate',
      { fromCategory: category.idCategory, toCategory: toCategory.idCategory, isDelete },
    );
    mutate('https://us-central1-clube-do-leilao.cloudfunctions.net/categories-index?withTotal=true');

    setLoading(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => !loading && onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ paddingTop: 3 }}>
        SELECIONE PARA QUAL CATEGORIA VOCÊ DESEJA MIGRAR OS LEILÕES QUE ESTÃO
        CADASTRADOS COMO:
        {' '}
        <b>{category?.name}</b>
      </DialogTitle>
      <DialogContent sx={{ marginTop: 1 }}>
        <Grid container>
          {categories
            .filter((main) => !main.subCategoryOf)
            .map((main) => (
              <CategoriesList
                main={main}
                categories={categories}
                selected={toCategory}
                onSelect={setToCategory}
              />
            ))}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: 3 }}>
        <Grid container direction="column">
          {toCategory && (
            <Typography sx={{ mb: 3, fontStyle: 'italic', color: 'secondary.main' }}>
              Você está migrando os leilões de
              {' '}
              <b>{category.name}</b>
              {' '}
              para
              {' '}
              <b>{toCategory.name}</b>
            </Typography>
          )}

          <Grid container wrap="nowrap" alignItems="center" sx={{ mb: 3 }}>
            <Checkbox
              color="secondary"
              checked={isDelete}
              onChange={(e) => setIsDelete(e.target.checked)}
            />
            <Typography>
              Você deseja excluir a categoria
              {' '}
              <b>{category.name}</b>
              {' '}
              após a migração?
            </Typography>
          </Grid>

          <Grid container wrap="nowrap" alignItems="center" justifyContent="flex-end">
            <Button disabled={loading} variant="outlined" color="secondary" onClick={onClose} sx={{ mr: 2 }}>
              CANCELAR
            </Button>
            <LoadingButton loading={loading} disabled={!toCategory} variant="contained" color="primary" onClick={handleConfirm}>
              MIGRAR
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

MigrateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  category: PropTypes.shape({
    idCategory: PropTypes.string,
    name: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
};

MigrateDialog.defaultProps = {
  category: null,
};

export default MigrateDialog;
