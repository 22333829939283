import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import DeleteDialog from '@components/dialog-delete';
import { useAuthContext } from '@contexts/auth';

import {
  X, Trash2, Edit2, Check, MapPin, ArrowRight,
} from 'react-feather';

import styles from './style';

const now = new Date();

function ViewAuction({
  open, auction, onClose, onEdit, canEdit,
}) {
  const [authState] = useAuthContext();

  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const approve = async () => {
    setLoading(true);
    const data = {
      idAuction: auction.idAuction,
      category: auction.categoryID,
      company: auction.companyID,
      name: auction.auctionName,
      images: auction.images,
      cover: auction.cover,
      instance: auction.instance,
      city: auction.city,
      state: auction.state,
      startAt: auction.startAt,
      endAt: auction.endAt,
      description: auction.description,
      value: auction.value,
      link: auction.link,
      approved: true,
    };

    await axios.post(
      'https://us-central1-clube-do-leilao.cloudfunctions.net/auction-update',
      data,
    );
    setLoading(false);
    onClose();
  };

  return (
    <Drawer
      anchor="right"
      open={!!open}
      onClose={() => onClose()}
      PaperProps={{ sx: { backgroundImage: 'none' } }}
    >
      <Grid container direction="column" sx={{ width: '500px', padding: 4, paddingBottom: 0 }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Typography fontWeight={800} fontSize={20}>
            Visualizar leilão
          </Typography>
          <IconButton onClick={() => onClose()}>
            <X />
          </IconButton>
        </Grid>
        <Grid container direction="column" sx={{ flexGrow: 1, overflow: 'auto', padding: '32px 0' }}>
          {auction?.reports && (
            <Grid item container diretion="column">
              {auction?.reports.map((report) => (
                <Grid container diretion="column" sx={styles.reportContainer}>
                  <Grid item container alignItems="center" wrap="nowrap">
                    <Avatar sx={styles.reportAvatar} src={report.avatar}>
                      {report.initials}
                    </Avatar>
                    <Typography sx={styles.reportName}>{report.userName}</Typography>
                  </Grid>
                  <Typography sx={styles.reportDescription}>{report.description}</Typography>
                </Grid>
              ))}
            </Grid>
          )}
          <Grid item container>
            <Swiper pagination modules={[Pagination]} style={{ width: '100%' }}>
              {auction?.images?.map((img) => (
                <SwiperSlide>
                  <Grid
                    container
                    sx={styles.carouselImage}
                    style={{ backgroundImage: `url(${img})` }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
            sx={{ margin: '32px 0 16px' }}
          >
            <Chip label={auction?.categoryName} color="primary" sx={{ borderRadius: 1 }} />
            {auction?.instance === 0 && <Chip label="PRAÇA ÚNICA" color="secondary" sx={{ borderRadius: 1 }} />}
            {auction?.instance === 1 && new Date(auction?.endFirstAt) > new Date() && <Chip label="1ª PRAÇA" color="secondary" sx={{ borderRadius: 1 }} />}
            {(auction?.instance === 2 || (auction?.instance === 1 && new Date(auction?.endFirstAt) <= new Date())) && <Chip label="2ª PRAÇA" color="secondary" sx={{ borderRadius: 1 }} />}
          </Grid>
          <Grid item container>
            <Typography fontSize={22}>
              {auction?.auctionName}
            </Typography>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
            sx={{ margin: '16px 0 32px', opacity: 0.7 }}
          >
            <Grid container alignItems="center" wrap="nowrap" sx={{ width: 'max-content' }}>
              <MapPin size={16} style={{ marginRight: 8 }} />
              <Typography fontSize={14}>{`${auction?.city} - ${auction?.state}`}</Typography>
            </Grid>
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              sx={{ width: 'max-content', cursor: 'pointer' }}
              onClick={() => window.open(auction?.companySite)}
            >
              <img
                src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${auction?.companySite}`}
                alt={auction?.companyName}
                style={{ width: 18, height: 18, marginRight: 8 }}
              />
              <Typography fontSize={14}>{auction?.companyName}</Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
            sx={{ marginBottom: 3, opacity: 0.7 }}
          >
            <Grid container direction="column" sx={{ width: 'max-content' }}>
              <Typography fontSize={12} fontWeight={800} color="secondary">
                INICIA EM
              </Typography>
              <Typography fontWeight={800}>
                {auction && format(new Date(auction?.startAt), 'dd/MM/yyyy HH:mm')}
              </Typography>
            </Grid>
            <Grid container direction="column" alignItems="flex-end" sx={{ width: 'max-content' }}>
              <Typography fontSize={12} fontWeight={800} color="secondary">
                ENCERRA EM
              </Typography>
              <Typography fontWeight={800}>
                {auction && format(new Date(auction?.endAt), 'dd/MM/yyyy HH:mm')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container>
            <Typography lineHeight={1.6} color="textSecondary">
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: auction?.reports ? auction?.auctionDescription : auction?.description,
                }}
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="column" wrap="nowrap" sx={{ marginBottom: 2 }}>
          <Typography color="secondary" fontSize={30} fontWeight={800} sx={{ margin: '16px 0' }}>
            R$
            {' '}
            {auction?.value?.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}
          </Typography>
          <Button
            fullWidth
            variant="contained"
            sx={{ padding: 1.2, fontWeight: 800, fontSize: 16 }}
            onClick={() => window.open(auction?.link)}
          >
            VISITE O LOTE
            <ArrowRight style={{ marginLeft: 16, marginTop: -1 }} />
          </Button>
        </Grid>
        {canEdit && auction && new Date(auction?.endAt) > now ? (
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            wrap="nowrap"
            spacing={2}
            sx={{
              padding: '16px 0 32px', position: 'sticky', bottom: 0, background: '#0D0D0D',
            }}
          >
            {!auction?.approved && (
              <Grid container item>
                <LoadingButton
                  fullWidth
                  loading={loading}
                  size="large"
                  variant="contained"
                  color="success"
                  onClick={approve}
                  disabled={!!authState.user?.companyId}
                >
                  {authState.user?.companyId ? 'Aguardando aprovação' : (
                    <>
                      <Check style={{ marginRight: 8 }} />
                      Aprovar
                    </>
                  )}
                </LoadingButton>
              </Grid>
            )}
            <Grid item>
              <Tooltip title="Editar leilão">
                <Button
                  disabled={loading}
                  size="large"
                  variant="contained"
                  color="secondary"
                  onClick={onEdit}
                  sx={{ minWidth: 0, padding: '11px' }}
                >
                  <Edit2 size={20} />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Excluir leilão">
                <Button
                  disabled={loading}
                  size="large"
                  variant="contained"
                  color="error"
                  onClick={() => setOpenDelete(true)}
                  sx={{ minWidth: 0, padding: '11px' }}
                >
                  <Trash2 size={20} />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        ) : <Grid container sx={{ height: 32 }} />}
      </Grid>

      <DeleteDialog
        open={!!openDelete}
        title={`Tem certeza que deseja remover o leilão de ${auction?.auctionName}?`}
        onDelete={async () => {
          await axios.post(
            'https://us-central1-clube-do-leilao.cloudfunctions.net/auction-delete',
            auction,
          );
          setOpenDelete(false);
          onClose();
        }}
        onClose={() => setOpenDelete(false)}
      />
    </Drawer>
  );
}

ViewAuction.propTypes = {
  auction: PropTypes.shape({
    idAuction: PropTypes.string,
    auctionName: PropTypes.string,
    categoryName: PropTypes.string,
    cover: PropTypes.string,
    companyID: PropTypes.number,
    categoryID: PropTypes.number,
    city: PropTypes.string,
    state: PropTypes.string,
    companyName: PropTypes.string,
    companySite: PropTypes.string,
    auctionDescription: PropTypes.string,
    description: PropTypes.string,
    startAt: PropTypes.string,
    endFirstAt: PropTypes.string,
    endAt: PropTypes.string,
    link: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    reports: PropTypes.arrayOf(PropTypes.shape({})),
    instance: PropTypes.number,
    value: PropTypes.number,
    approved: PropTypes.bool,
  }),
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

ViewAuction.defaultProps = {
  auction: {},
};

export default ViewAuction;
