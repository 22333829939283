export default [
  {
    date: '2023-06-25T02:38:29.417',
    data: {
      users: {
        active: 100,
        subscriptions: 5,
        new: 257,
      },
      auctions: {
        pageviews: 1254,
        linkAccess: 589,
      },
    },
  },
  {
    date: '2023-06-26T02:38:29.417',
    data: {
      users: {
        active: 80,
        subscriptions: 8,
        new: 384,
      },
      auctions: {
        pageviews: 987,
        linkAccess: 234,
      },
    },
  },
  {
    date: '2023-06-27T02:38:29.417',
    data: {
      users: {
        active: 110,
        subscriptions: 16,
        new: 259,
      },
      auctions: {
        pageviews: 1147,
        linkAccess: 225,
      },
    },
  },
  {
    date: '2023-06-28T02:38:29.417',
    data: {
      users: {
        active: 100,
        subscriptions: 12,
        new: 150,
      },
      auctions: {
        pageviews: 1926,
        linkAccess: 687,
      },
    },
  },
  {
    date: '2023-06-29T02:38:29.417',
    data: {
      users: {
        active: 105,
        subscriptions: 22,
        new: 269,
      },
      auctions: {
        pageviews: 1657,
        linkAccess: 620,
      },
    },
  },
  {
    date: '2023-06-30T02:38:29.417',
    data: {
      users: {
        active: 90,
        subscriptions: 23,
        new: 316,
      },
      auctions: {
        pageviews: 1302,
        linkAccess: 541,
      },
    },
  },
  {
    date: '2023-07-01T02:38:29.417',
    data: {
      users: {
        active: 115,
        subscriptions: 14,
        new: 357,
      },
      auctions: {
        pageviews: 1110,
        linkAccess: 550,
      },
    },
  },
];
