import { alpha } from '@mui/material/styles';

export default {
  addItem: (theme) => ({
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    padding: '12px 16px',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.4),
    },
  }),
};
