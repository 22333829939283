import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Checkbox, IconButton } from '@mui/material';
import { ArrowRight } from 'react-feather';

const now = new Date();

function Row({
  auction, onClick, onSeeMore, isSelected, checkbox,
}) {
  return (
    <TableRow selected={isSelected} sx={{ cursor: 'pointer' }} hover onClick={checkbox ? onClick : onSeeMore}>
      {checkbox && (
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isSelected}
            onClick={onClick}
          />
        </TableCell>
      )}
      <TableCell>
        {auction.idAuction}
      </TableCell>
      {auction.approved && (new Date(auction.startAt) > now || new Date(auction.endAt) > now) && (
        <TableCell>
          {new Date(auction.startAt) > now && (
            <Chip label="PARA ACONTECER" color="primary" />
          )}
          {new Date(auction.startAt) < now && new Date(auction.endAt) > now && (
            <Chip label="ACONTECENDO" color="primary" />
          )}
        </TableCell>
      )}
      <TableCell>
        <Chip label={auction.categoryName} color="primary" />
      </TableCell>
      <TableCell>
        {auction.auctionName}
      </TableCell>
      <TableCell>
        <Grid container alignItems="center" wrap="nowrap" sx={{ width: 'min-content' }}>
          <img
            src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${auction.companySite}`}
            alt={auction.companyName}
            style={{ width: 18, height: 18, marginRight: 8 }}
          />
          {auction.companyName}
        </Grid>
      </TableCell>
      <TableCell align="center">
        {auction.instance === 0 && <Chip label="ÚNICA" color="primary" />}
        {auction.instance === 1 && new Date(auction.endFirstAt) > new Date() && <Chip label="1ª PRAÇA" color="primary" />}
        {(auction.instance === 2 || (auction.instance === 1 && new Date(auction.endFirstAt) <= new Date())) && <Chip label="2ª PRAÇA" color="primary" />}
      </TableCell>
      <TableCell align="center">
        {format(new Date(auction.startAt), 'dd/MM/yyyy HH:mm')}
      </TableCell>
      <TableCell align="center">
        {format(new Date(auction.endAt), 'dd/MM/yyyy HH:mm')}
      </TableCell>
      <TableCell align="center">
        {auction.views}
      </TableCell>
      <TableCell align="center">
        {auction.visits}
      </TableCell>
      {checkbox && (
        <TableCell align="center">
          <IconButton onClick={onSeeMore}>
            <ArrowRight />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );
}

Row.propTypes = {
  checkbox: PropTypes.bool,
  auction: PropTypes.shape({
    idAuction: PropTypes.number.isRequired,
    auctionName: PropTypes.string.isRequired,
    categoryName: PropTypes.string,
    startAt: PropTypes.string,
    endFirstAt: PropTypes.string,
    endAt: PropTypes.string,
    companyName: PropTypes.string,
    companySite: PropTypes.string,
    instance: PropTypes.number,
    approved: PropTypes.bool,
    views: PropTypes.number,
    visits: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  onSeeMore: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

Row.defaultProps = {
  checkbox: false,
};

export default Row;
