import React, { useState, useEffect } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import fetcher from '@services/fetcher';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import Card from '@components/card';
import Loading from '@components/loading';

import { useAuthContext } from '@contexts/auth';

import { Plus, XCircle, Package } from 'react-feather';

// import styles from './style';

import Row from './row';
import FormCompany from './form-company';
import FormUser from './form-user';

function Companies() {
  const navigate = useNavigate();

  const { mutate } = useSWRConfig();
  const { data: companies, error } = useSWR(
    'https://us-central1-clube-do-leilao.cloudfunctions.net/companies-index',
    fetcher,
  );

  const [authState] = useAuthContext();

  const [openDrawerCompany, setOpenDrawerCompany] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const [openDrawerUser, setOpenDrawerUser] = useState(false);

  const [canEdit, setCanEdit] = useState(null);

  useEffect(() => {
    if (authState.user?.companyId) navigate('/');
    else if (!authState.user?.owner && !authState.user?.permissions?.companies?.read) navigate('/');
    else setCanEdit(authState.user?.owner || authState.user?.permissions?.companies?.edit);
  }, [authState]);

  const onClose = () => {
    setSelectedCompany(null);
    setOpenDrawerCompany(false);
    setOpenDrawerUser(false);
    mutate('https://us-central1-clube-do-leilao.cloudfunctions.net/companies-index');
  };

  return (
    <Grid
      container
      wrap="nowrap"
      justifyContent="center"
      alignItems="center"
    >
      <Card>
        <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 3 }}>
          <Typography color="primary" variant="h4">
            LEILOEIROS
          </Typography>
          {canEdit && (
            <Button size="large" variant="contained" color="primary" onClick={() => setOpenDrawerCompany(true)}>
              <Plus style={{ marginRight: 8 }} />
              Adicionar leiloeiro
            </Button>
          )}
        </Grid>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead sx={{
              position: 'sticky', top: 0, backgroundColor: 'background.default', zIndex: 10,
            }}
            >
              <TableRow>
                <TableCell style={{ width: 34 }} />
                <TableCell style={{ width: 24 }} />
                <TableCell sx={{ fontWeight: 800, color: 'secondary.main' }}>
                  Nome
                </TableCell>
                <TableCell sx={{ fontWeight: 800, color: 'secondary.main' }}>
                  Site
                </TableCell>
                <TableCell sx={{ fontWeight: 800, color: 'secondary.main' }}>
                  Atuação
                </TableCell>
                <TableCell align="center" sx={{ width: 110, fontWeight: 800, color: 'secondary.main' }}>
                  <Package />
                </TableCell>
                {canEdit && (
                  <TableCell style={{ width: 108 }} />
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!error && !companies && (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Loading height={300} />
                  </TableCell>
                </TableRow>
              )}
              {!error && companies?.map((row) => (
                <Row
                  key={row.idCompany}
                  row={row}
                  onEdit={(data) => {
                    setSelectedCompany(data);
                    setOpenDrawerCompany(true);
                  }}
                  onAdd={(id) => {
                    setSelectedCompany(id);
                    setOpenDrawerUser(true);
                  }}
                  canEdit={canEdit}
                />
              ))}
              {error && (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      wrap="nowrap"
                      sx={{ padding: 4 }}
                    >
                      <XCircle size={58} color="#f44336" style={{ strokeWidth: 1, marginRight: 23 }} />
                      <Typography color="textSecondary" fontSize={18} fontWeight={200}>
                        Ocorreu algum erro com os leiloeiros! Por favor, entre em contato com o
                        suporte
                      </Typography>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <FormCompany
        open={!!openDrawerCompany}
        company={!openDrawerUser ? selectedCompany : null}
        companies={companies || []}
        onClose={onClose}
      />

      <FormUser
        open={!!openDrawerUser}
        companyId={selectedCompany}
        companies={companies || []}
        onClose={onClose}
      />
    </Grid>
  );
}

export default Companies;
