import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Grid, TextField } from '@mui/material';

import { Search, X } from 'react-feather';

function SearchInput({ value, label, onChange }) {
  const input = useRef();

  const [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    if (openSearch && input.current) input.current.focus();
    else if (!openSearch) onChange('');
  }, [openSearch, input.current, onChange]);

  return (
    <Grid
      container
      wrap="nowrap"
      sx={{
        backgroundColor: 'primary.main',
        borderRadius: 1,
        width: 'min-content',
        mr: 2,
      }}
    >
      <Button
        size="large"
        variant="contained"
        color="primary"
        onClick={() => setOpenSearch(!openSearch)}
      >
        {openSearch ? <X /> : <Search />}
      </Button>

      <div
        style={{
          overflow: 'hidden',
          width: openSearch ? 300 : 0,
          transition: 'width 0.3s',
        }}
      >
        <TextField
          fullWidth
          placeholder={label}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          sx={{
            minWidth: 250,
            '& fieldset': { border: 'none !important' },
          }}
        />
      </div>
    </Grid>
  );
}

SearchInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchInput;
