import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import fetcher from '@services/fetcher';
import axios from 'axios';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';

import Card from '@components/card';
import Loading from '@components/loading';
import DeleteDialog from '@components/dialog-delete';

import { useAuthContext } from '@contexts/auth';

import {
  Plus, XCircle, Eye, ExternalLink, Filter, Check, Trash2,
} from 'react-feather';

import ChangeAuctionsCategories from '@components/change-auctions-categories';

// import styles from './style';

import { Checkbox, CircularProgress } from '@mui/material';
import FormAuction from './form';
import FilterAuctions from './filter';
import ViewAuction from './view';
import Row from './row';

const headCells = [
  {
    id: 'category',
    numeric: false,
    label: 'Categoria',
  },
  {
    id: 'name',
    numeric: false,
    label: 'Nome',
  },
  {
    id: 'site',
    numeric: false,
    label: 'Site',
  },
  {
    id: 'instance',
    numeric: true,
    label: 'Praça',
  },
  {
    id: 'startAt',
    numeric: false,
    label: 'Inicia em',
  },
  {
    id: 'endAt',
    numeric: false,
    label: 'Encerra em',
  },
  {
    id: 'views',
    numeric: true,
    label: <Tooltip title="Visualizações"><Eye /></Tooltip>,
  },
  {
    id: 'visits',
    numeric: true,
    label: <Tooltip title="Visitas no site"><ExternalLink /></Tooltip>,
  },
];

const tabs = [
  { id: 'actived', label: 'Ativos' },
  { id: 'approve', label: 'Aprovação' },
  { id: 'finished', label: 'Encerrados' },
  { id: 'reports', label: 'Reports' },
];

const tabNumbersDefault = {
  actived: 0, approve: 0, finished: 0, reports: 0,
};

function Companies() {
  const navigate = useNavigate();

  const { data: companies } = useSWR(
    'https://us-central1-clube-do-leilao.cloudfunctions.net/companies-index',
    fetcher,
  );
  const { data: categories } = useSWR(
    'https://us-central1-clube-do-leilao.cloudfunctions.net/categories-index?withTotal=true',
    fetcher,
  );

  const [authState] = useAuthContext();

  const [tab, setTab] = useState('actived');
  const [tabNumbers, setTabNumbers] = useState(tabNumbersDefault);

  const [auctions, setAuctions] = useState(null);
  const [selectedAuctions, setSelectedAuctions] = useState([]);
  const [error, setError] = useState(null);

  const [filter, setFilter] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('startAt');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [page, setPage] = useState(0);

  const [selectedAuction, setSelectedAuction] = useState(null);
  const [openView, setOpenView] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [loadingSelected, setLoadingSelected] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState(false);

  const [canEdit, setCanEdit] = useState(null);

  const getAuctions = useCallback(async () => {
    setAuctions(null);
    let query = `orderBy=${orderBy}&orderDirection=${order}&page=${page}&limit=${rowsPerPage}&tab=${tab}`;
    if (authState.user?.companyId) query += `&companyId=${authState.user?.companyId}`;
    if (filter) query += `&${filter}`;
    const { data, status } = await axios.get(
      `https://us-central1-clube-do-leilao.cloudfunctions.net/auctions-index?${query}`,
    );

    setError(status !== 200);
    if (status === 200) {
      if (tab === 'reports') {
        const auctionsReport = [];
        data.auctions.forEach((auction) => {
          const index = auctionsReport.findIndex((a) => a.idAuction === auction.idAuction);
          if (index >= 0) auctionsReport[index].reports.push(auction);
          else auctionsReport.push({ ...auction, reports: [auction] });
        });
        setAuctions(auctionsReport);
      } else setAuctions(data.auctions);
      setSelectedAuctions([]);
    }
  }, [order, orderBy, rowsPerPage, page, tab, authState.user, filter]);

  useEffect(() => {
    if (!authState.user?.companyId && !authState.user?.owner && !authState.user?.permissions?.auctions?.read) navigate('/');
    else {
      setCanEdit(authState.user?.companyId
        || authState.user?.owner
        || authState.user?.permissions?.auctions?.edit);
    }
  }, [authState]);

  useEffect(() => {
    const getTabNumbers = async () => {
      let query = '';
      if (filter) query += `?${filter}`;
      if (authState.user?.companyId) query = `${!query ? '?' : '&'}companyId=${authState.user?.companyId}`;
      const { data, status } = await axios.get(
        `https://us-central1-clube-do-leilao.cloudfunctions.net/auctions-numbers${query}`,
      );
      if (status === 200) setTabNumbers(data);
    };

    if (!openView && !openForm) getTabNumbers();
  }, [openView, openForm, filter]);

  useEffect(() => {
    if (!openView && !openForm) getAuctions();
  }, [openView, openForm, getAuctions]);

  const onClose = () => {
    setSelectedAuction(null);
    setOpenView(false);
    setOpenForm(false);
  };

  const handleRequestSort = (property) => () => {
    setAuctions(null);
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setAuctions(null);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const changeTab = (id) => {
    if (id !== tab) {
      setPage(0);
      setAuctions(null);
      setTab(id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) setSelectedAuctions(auctions);
    else setSelectedAuctions([]);
  };

  const approveAll = async () => {
    setLoadingSelected(true);
    await axios.post(
      'https://us-central1-clube-do-leilao.cloudfunctions.net/auctions-update',
      { approved: true, idsAuction: selectedAuctions.map((a) => a.idAuction) },
    );
    setLoadingSelected(false);
    getAuctions();
    setSelectedAuctions([]);
  };

  const deleteAll = async () => {
    setLoadingSelected(true);
    await axios.post(
      'https://us-central1-clube-do-leilao.cloudfunctions.net/auctions-delete',
      { idsAuction: selectedAuctions.map((a) => a.idAuction) },
    );
    setLoadingSelected(false);
    setDeleteSelected(false);
    getAuctions();
    setSelectedAuctions([]);
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - tabNumbers[tab]);
  const rowCount = rowsPerPage - emptyRows;
  const numSelected = selectedAuctions.length;

  return (
    <Grid
      container
      wrap="nowrap"
      justifyContent="center"
      alignItems="center"
    >
      <Card>
        <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 4 }}>
          <Grid container wrap="nowrap" alignItems="center" spacing={2} sx={{ width: 'min-content' }}>
            {tabs.map((t) => (
              <Grid item key={t.id}>
                <Button
                  size="large"
                  color="secondary"
                  variant={tab === t.id ? 'contained' : 'outlined'}
                  onClick={() => changeTab(t.id)}
                  sx={{ whiteSpace: 'nowrap' }}
                  disabled={tabNumbers[t.id] === 0 || (!auctions && !error)}
                >
                  {`${t.label} (${tabNumbers[t.id]})`}
                </Button>
              </Grid>
            ))}
          </Grid>
          {canEdit && (
            <Grid container wrap="nowrap" alignItems="center" sx={{ width: 'max-content' }}>
              {selectedAuctions.length > 0 ? (
                <>
                  <ChangeAuctionsCategories
                    auctions={selectedAuctions}
                    categories={categories}
                    disabled={loadingSelected}
                    onClose={() => {
                      setSelectedAuctions([]);
                      getAuctions();
                    }}
                  />
                  <Tooltip title="Aprovar os leilões selecionados">
                    <Button
                      size="large"
                      variant="contained"
                      color="success"
                      onClick={approveAll}
                      sx={{ minWidth: 0, ml: 2, p: 2 }}
                      disabled={loadingSelected}
                    >
                      {loadingSelected && !deleteSelected ? (
                        <CircularProgress color="primary" size={24} />
                      ) : (
                        <Check />
                      )}
                    </Button>
                  </Tooltip>
                  <Tooltip title="Excluir os leilões selecionados">
                    <Button
                      size="large"
                      variant="contained"
                      color="error"
                      onClick={() => setDeleteSelected(true)}
                      sx={{ minWidth: 0, ml: 2, p: 2 }}
                      disabled={loadingSelected}
                    >
                      <Trash2 />
                    </Button>
                  </Tooltip>

                  <DeleteDialog
                    open={deleteSelected}
                    title={`Você tem certeza que deseja deletar ${selectedAuctions?.length || 0} leilões?`}
                    onDelete={deleteAll}
                    onClose={() => setDeleteSelected(false)}
                  />
                </>
              ) : (
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenForm(true)}
                  sx={{ p: 2 }}
                >
                  <Plus style={{ marginRight: 8 }} />
                  Adicionar leilão
                </Button>
              )}
              <Tooltip title="Filtrar leilões">
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenFilter(true)}
                  sx={{ minWidth: 0, ml: 2, p: 2 }}
                >
                  <Filter />
                </Button>
              </Tooltip>
            </Grid>
          )}
        </Grid>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead sx={{
              position: 'sticky', top: 0, backgroundColor: 'background.default', zIndex: 10,
            }}
            >
              <TableRow>
                {tab === 'approve' && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && numSelected === rowCount}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        'aria-label': 'selecione todos os leilões',
                      }}
                    />
                  </TableCell>
                )}
                <TableCell
                  sx={{ color: 'secondary.main', fontWeight: 800, whiteSpace: 'nowrap' }}
                >
                  ID
                </TableCell>
                {(tab === 'actived' || tab === 'reports') && (
                  <TableCell
                    sx={{ color: 'secondary.main', fontWeight: 800, whiteSpace: 'nowrap' }}
                  >
                    Status
                  </TableCell>
                )}
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'center' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    sortDirection={orderBy === headCell.id ? order : false}
                    sx={{ color: 'secondary.main', fontWeight: 800, whiteSpace: 'nowrap' }}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={handleRequestSort(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
                {tab === 'approve' && <TableCell />}
              </TableRow>
            </TableHead>
            <TableBody>
              {!auctions && !error && (
                <TableRow>
                  <TableCell colSpan={10}>
                    <Loading height={300} />
                  </TableCell>
                </TableRow>
              )}
              {auctions?.map((row, index) => (
                <Row
                  checkbox={tab === 'approve'}
                  key={row.idAuction}
                  auction={row}
                  isSelected={selectedAuctions.includes(row)}
                  onClick={(e) => {
                    e.stopPropagation();
                    const selected = [...selectedAuctions];

                    const selectedIndex = selected.findIndex((a) => a.idAuction === row.idAuction);

                    if (selectedIndex !== -1) selected.splice(selectedIndex, 1);
                    else selected.push(row);

                    setSelectedAuctions(selected);
                  }}
                  onSeeMore={() => {
                    setSelectedAuction({ ...row, index });
                    if (tab === 'approve') setOpenForm(true);
                    else setOpenView(true);
                  }}
                />
              ))}
              {auctions && !error && emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={10} />
                </TableRow>
              )}
              {error && (
                <TableRow>
                  <TableCell colSpan={10}>
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                      wrap="nowrap"
                      sx={{ padding: 4 }}
                    >
                      <XCircle size={58} color="#f44336" style={{ strokeWidth: 1, marginRight: 23 }} />
                      <Typography color="textSecondary" fontSize={18} fontWeight={200}>
                        Ocorreu algum erro com os leiloeiros! Por favor, entre em contato com o
                        suporte
                      </Typography>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          showFirstButton
          showLastButton
          labelRowsPerPage="Leilões por página"
          labelDisplayedRows={({
            from, to, count,
          }) => `${from}–${to} de ${count !== -1 ? count : `mais que ${to}`}`}
          rowsPerPageOptions={[50, 100, 200]}
          component="div"
          count={tabNumbers[tab]}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <FormAuction
        isApprove={tab === 'approve'}
        open={openForm}
        auction={openForm && selectedAuction}
        onClose={onClose}
        categories={categories}
        companies={companies}
        next={() => {
          const nextIndex = selectedAuction.index + 1;
          const nextAuction = auctions[nextIndex];
          if (nextAuction) setSelectedAuction({ ...nextAuction, index: nextIndex });
          else onClose();
        }}
      />

      <FilterAuctions
        open={openFilter}
        filter={filter}
        onChange={(value) => {
          setTabNumbers(tabNumbersDefault);
          setAuctions(null);
          setFilter(value);
          setOpenFilter(false);
        }}
        categories={categories}
        companies={companies}
      />

      <ViewAuction
        open={openView}
        auction={selectedAuction}
        onClose={onClose}
        onEdit={() => {
          setOpenView(false);
          setOpenForm(true);
        }}
        canEdit={canEdit}
      />
    </Grid>
  );
}

export default Companies;
