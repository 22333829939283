import React, { useState } from 'react';

import { auth } from '@services/firebase';
import { signOut } from 'firebase/auth';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import Loading from '@components/loading';
import Card from '@components/card';

import { useAuthContext } from '@contexts/auth';

import { Key, Edit3, LogOut } from 'react-feather';

import FormProfile from './form-profile';
import FormPassword from './form-password';

import styles from './style';

const getInitials = (name) => {
  if (name) {
    const splitted = name.split(' ');
    if (splitted.length > 1) {
      return (splitted[0][0] + splitted.reverse()[0][0]).toUpperCase();
    }

    return (splitted[0][0] + splitted[0][1]).toUpperCase();
  }
  return null;
};

function Companies() {
  const [authState] = useAuthContext();

  const [openProfile, setOpenProfile] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);

  if (authState.loading || !authState.user) return <Loading />;

  return (
    <Grid
      container
      wrap="nowrap"
      justifyContent="center"
      alignItems="center"
    >
      <Card>
        <Grid container wrap="nowrap" alignItems="center" sx={{ padding: 2 }}>
          <Avatar sx={styles.avatar} src={authState.user.photoURL}>
            {getInitials(authState.user.displayName)}
          </Avatar>
          <Grid container direction="column">
            <Typography color="textPrimary" variant="h4" fontWeight={800} sx={{ marginBottom: 1 }}>
              {authState.user.displayName}
            </Typography>
            <Typography color="textSecondary" sx={{ marginBottom: 4 }}>
              {authState.user.email}
            </Typography>
            <Grid container>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                sx={{ padding: '12px 24px', marginRight: 2 }}
                onClick={() => setOpenProfile(true)}
              >
                <Edit3 size={20} style={{ marginRight: 16 }} />
                <Typography fontWeight={800}>Editar perfil</Typography>
              </Button>
              <Button
                size="large"
                variant="contained"
                color="primary"
                sx={{ padding: '12px 24px' }}
                onClick={() => setOpenPassword(true)}
              >
                <Key size={20} style={{ marginRight: 16 }} />
                <Typography fontWeight={800}>Alterar senha</Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Tooltip title="Sair">
              <IconButton color="error" size="large" onClick={() => signOut(auth)}>
                <LogOut size={32} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>

      <FormProfile open={!!openProfile} onClose={() => setOpenProfile(false)} />
      <FormPassword open={!!openPassword} onClose={() => setOpenPassword(false)} />
    </Grid>
  );
}

export default Companies;
