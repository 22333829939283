/* eslint-disable no-await-in-loop */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSWRConfig } from 'swr';
import validator from 'validator';
import axios from 'axios';
import { format } from 'date-fns';
import { storage } from '@services/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { useAuthContext } from '@contexts/auth';

import useCities from '@hooks/useCities';

import {
  X, CornerDownRight, Plus, Calendar, Trash2, Check, ExternalLink,
} from 'react-feather';

import FormCompany from '@app/companies/form-company';
import FormCategory from '@app/categories/form';
import DeleteDialog from '@components/dialog-delete';

import { Autocomplete, Tooltip } from '@mui/material';

import styles from './style';

const errorsDefault = {
  category: false,
  company: false,
  name: false,
  praca: false,
  city: false,
  state: false,
  startAt: false,
  endFirstAt: false,
  startSecondAt: false,
  endAt: false,
  value: false,
  link: false,
};

function PicketIcon() {
  return <Calendar size={18} style={{ opacity: 0.8 }} />;
}

const handleCurrency = (set) => (e) => {
  const { value: string } = e.target;

  const cleanString = string
    .replaceAll('.', '')
    .replaceAll(',', '')
    .replaceAll('e', '')
    .replaceAll('+', '')
    .replaceAll('-', '');
  const value = `${cleanString.slice(0, -2)}.${cleanString.slice(-2)}`;
  set(parseFloat(value));
};

const numberToCurrency = (number) => {
  if (!number) return '0,00';
  return number.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

const isOficial = (string, original) => {
  const link = (`https://${string}`).replace('https://http', 'http');
  const endDomain = link.slice(9).indexOf('/');
  const domain = link.slice(0, endDomain + 10);

  const igualateUrl = (url) => {
    const newUrl = url.replace('http://', 'https://');
    if (newUrl.slice(-1) === '/') return newUrl;
    return `${newUrl}/`;
  };

  return igualateUrl(domain) === igualateUrl(original);
};

const formatCity = (city) => ({ id: city.idCity, label: `${city.name} - ${city.state}` });

function FormAuction({
  isApprove, open, auction, onClose, categories, companies, next,
}) {
  const { mutate } = useSWRConfig();

  const [authState] = useAuthContext();

  const [category, setCategory] = useState('');
  const [company, setCompany] = useState('');
  const [name, setName] = useState('');
  const [images, setImages] = useState([]);
  const [cover, setCover] = useState('');
  const [praca, setPraca] = useState('');
  const [city, setCity] = useState('');
  const [startAt, setStartAt] = useState(null);
  const [endFirstAt, setEndFirstAt] = useState(null);
  const [startSecondAt, setStartSecondAt] = useState(null);
  const [endAt, setEndAt] = useState(null);
  const [description, setDescription] = useState('');
  const [value, setValue] = useState('');
  const [link, setLink] = useState('');

  const [errors, setErrors] = useState({ ...errorsDefault });

  const [openCompany, setOpenCompany] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);

  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const cities = useCities();
  const [cityRef, setCityRef] = useState(null);

  useEffect(() => {
    if (city && cities.length > 0) {
      const selected = cities.find((item) => item.idCity === city);
      setCityRef(selected ? formatCity(selected) : null);
    }
  }, [city, cities]);

  useEffect(() => {
    if (authState.user) setCompany(authState.user.companyId || '');
  }, [authState.user]);

  useEffect(() => {
    if (auction) {
      setCategory(auction.categoryID);
      setCompany(auction.companyID);
      setName(auction.auctionName);
      setImages(auction.images);
      setCover(auction.cover);
      setPraca(auction.instance);
      setCity(auction.cityId);
      setStartAt(new Date(auction.startAt));
      setEndFirstAt(new Date(auction.endFirstAt));
      setStartSecondAt(new Date(auction.startSecondAt));
      setEndAt(new Date(auction.endAt));
      setDescription(auction.description);
      setValue(auction.value);
      setLink(auction.link);
    }
  }, [auction]);

  const handleClose = () => {
    if (!loading) {
      setCategory('');
      setCompany(authState.user.companyId || '');
      setName('');
      setImages([]);
      setCover('');
      setPraca('');
      setCity('');
      setStartAt(null);
      setEndFirstAt(null);
      setStartSecondAt(null);
      setEndAt(null);
      setDescription('');
      setValue('');
      setLink('');
      setErrors(errorsDefault);
      onClose();
    }
  };

  const removeImage = (i) => {
    const newImages = [...images];
    newImages.splice(i, 1);
    setImages(newImages);
  };

  const getDateStartError = (err) => {
    if (err === 'invalidDate') {
      setErrors({ ...errors, startAt: true });
    } else setErrors({ ...errors, startAt: err || false });
  };
  const getDateEndFirstError = (err) => {
    if (err === 'invalidDate') {
      setErrors({ ...errors, endFirstAt: true });
    } else if (err === 'minTime' || err === 'minDate') {
      setErrors({ ...errors, endFirstAt: 'O encerramento deve ser depois da data de inicio' });
    } else setErrors({ ...errors, endFirstAt: err || false });
  };
  const getDateStartSecondError = (err) => {
    if (err === 'invalidDate') {
      setErrors({ ...errors, startSecondAt: true });
    } else if (err === 'minTime' || err === 'minDate') {
      setErrors({ ...errors, startSecondAt: 'O início deve ser depois do encerramento da 1ª praça' });
    } else setErrors({ ...errors, startSecondAt: err || false });
  };
  const getDateEndError = (err) => {
    if (err === 'invalidDate') {
      setErrors({ ...errors, endAt: true });
    } else if (err === 'minTime' || err === 'minDate') {
      setErrors({ ...errors, endAt: 'O encerramento deve ser depois da data de inicio' });
    } else if (err === 'disablePast') {
      setErrors({ ...errors, endAt: 'Deve ser uma data no futuro' });
    } else setErrors({ ...errors, endAt: err || false });
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const errs = { ...errorsDefault };

    if (!category) errs.category = 'Selecione a categoria do leilão';
    if (!company) errs.company = 'Selecione um leiloeiro';
    if (!name) errs.name = 'O admin precisa ter um nome';
    if (praca === '') errs.praca = 'Selecione a praça';
    if (!city && authState.user?.companyId) errs.state = 'Selecione a cidade';
    if (errors.startAt !== 'Digite o início do leilão') errs.startAt = errors.startAt;
    else if (!startAt) errs.startAt = 'Digite o início do leilão';
    if (errors.endFirstAt !== 'Digite o encerramento da 1ª praça') errs.endFirstAt = errors.endFirstAt;
    else if (!endFirstAt) errs.endFirstAt = 'Digite o encerramento da 1ª praça';
    if (errors.startSecondAt !== 'Digite o início da 2ª praça') errs.startSecondAt = errors.startSecondAt;
    else if (!startSecondAt) errs.startSecondAt = 'Digite o início da 2ª praça';
    if (errors.endAt !== 'Digite o encerramento do leilão') errs.endAt = errors.endAt;
    else if (!endAt) errs.endAt = 'Digite o encerramento do leilão';
    if (!link) errs.link = 'Cole aqui o link do leilão';
    else if (!validator.isURL(link)) errs.link = 'Utilie um link válido';
    else {
      const currentCompany = companies.find((c) => c.idCompany === company);
      if (currentCompany && !isOficial(link, currentCompany.site)) {
        errs.link = 'Utilie um link válido';
      }
    }

    setErrors(errs);

    if (Object.keys(errs).some((k) => errs[k])) {
      setLoading(false);
      return;
    }

    const imagesUrls = [];
    let coverUrl = cover || images[0] || '';
    for (let i = 0; i < images.length; i += 1) {
      const img = images[i];
      if (typeof img === 'object') {
        const fileName = format(new Date(), 'yyyyMMddHHmmssSSSS');
        const imgRef = ref(storage, `auctions/${fileName}.png`);
        await uploadBytes(imgRef, img);
        const url = await getDownloadURL(imgRef);
        imagesUrls.push(url);
        if (img === coverUrl) coverUrl = url;
      } else {
        imagesUrls.push(img);
      }
    }

    const data = {
      category,
      company,
      name,
      images: imagesUrls,
      cover: coverUrl,
      instance: praca,
      city,
      startAt,
      endFirstAt,
      startSecondAt,
      endAt,
      description,
      value: value || 0,
      link,
      approved: isApprove || auction?.approved || !authState.user?.companyId,
    };

    try {
      if (!auction) {
        await axios.post('https://us-central1-clube-do-leilao.cloudfunctions.net/auction-store', data);
      } else {
        await axios.post(
          'https://us-central1-clube-do-leilao.cloudfunctions.net/auction-update',
          { ...data, idAuction: auction.idAuction },
        );
      }
      setLoading(false);
      if (isApprove) next();
      else handleClose();
    } catch (error) {
      const { response } = error;
      if (response.data === 'DUPLICATED LINK') {
        setErrors({ ...errs, link: 'Um leilão com este link já está cadastrado' });
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!openCompany && !openCategory) {
      mutate('https://us-central1-clube-do-leilao.cloudfunctions.net/companies-index');
      mutate('https://us-central1-clube-do-leilao.cloudfunctions.net/categories-index?withTotal=true');
    }
  }, [openCompany, openCategory]);

  if (openCompany) {
    return <FormCompany open companies={companies} onClose={() => setOpenCompany(false)} />;
  }

  if (openCategory) {
    return (
      <FormCategory
        open
        subCategoryOf={typeof openCategory !== 'boolean' ? openCategory : null}
        categories={categories}
        onClose={() => setOpenCategory(false)}
      />
    );
  }

  return (
    <Drawer
      anchor="right"
      open={!!open}
      onClose={handleClose}
      PaperProps={{ sx: { backgroundImage: 'none' } }}
    >
      <Grid container direction="column" sx={{ width: '500px', padding: 4, paddingBottom: 0 }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Typography fontWeight={800} fontSize={20}>
            {auction ? `Editar ${auction.auctionName}` : 'Adicionar leilão'}
          </Typography>
          <IconButton onClick={handleClose}>
            <X />
          </IconButton>
        </Grid>
        <form onSubmit={submit} style={{ width: '100%' }}>
          <Grid container spacing={3} sx={{ flexGrow: 1, padding: '32px 0' }}>
            <Grid item xs={12}>
              <TextField
                error={!!errors.company}
                fullWidth
                disabled={!!authState.user?.companyId}
                select
                label="Leiloeiro"
                value={company}
                color="secondary"
                onChange={(e) => {
                  if (e.target.value !== 'add') setCompany(e.target.value);
                  else setOpenCompany(true);
                }}
              >
                {companies?.map((item) => (
                  <MenuItem key={item.idCompany} value={item.idCompany}>
                    <Grid container alignItems="center">
                      <img
                        src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${item.site}`}
                        alt={item.name}
                        style={{ marginRight: 16 }}
                      />
                      {item.name}
                    </Grid>
                  </MenuItem>
                ))}
                <MenuItem value="add" sx={styles.addItem}>
                  Adicionar leiloeiro
                </MenuItem>
              </TextField>
              {errors.company && (
                <FormHelperText error>{errors.company}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                error={!!errors.category}
                fullWidth
                select
                label="Categoria"
                value={category}
                color="secondary"
                onChange={(e) => {
                  if (e.target.value !== 'add') setCategory(e.target.value);
                  else setOpenCategory(true);
                }}
                SelectProps={{ MenuProps: { style: { maxHeight: 500 } } }}
              >
                {!authState.user?.companyId && (
                  <MenuItem value="add" sx={styles.addItem}>
                    Adicionar categoria
                  </MenuItem>
                )}
                {categories?.filter((c) => !c.subCategoryOf).map((main) => [
                  (
                    <MenuItem key={main.idCategory} value={main.idCategory} sx={{ padding: '12px 24px', fontWeight: 800 }}>
                      <Grid container alignItems="center">
                        {main.name}
                      </Grid>
                    </MenuItem>
                  ),
                  categories?.filter((c) => c.subCategoryOf === main.idCategory).map((item) => (
                    <MenuItem key={item.idCategory} value={item.idCategory} sx={{ padding: '12px 24px' }}>
                      <Grid container alignItems="center">
                        <CornerDownRight size={14} style={{ margin: '0 16px 0 4px', opacity: 0.5 }} />
                        {item.name}
                      </Grid>
                    </MenuItem>
                  )),
                  !authState.user?.companyId && (
                    <MenuItem
                      onClick={() => setOpenCategory(main.idCategory)}
                      sx={styles.addItem}
                      style={{ padding: '12px 24px 12px 58px' }}
                    >
                      Adicionar sub-categoria de&nbsp;
                      <b>{main.name}</b>
                    </MenuItem>
                  ),
                ])}
              </TextField>
              {errors.category && (
                <FormHelperText error>{errors.category}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                error={!!errors.praca}
                fullWidth
                select
                label="Praça"
                value={praca}
                color="secondary"
                onChange={(e) => setPraca(e.target.value)}
                SelectProps={{ MenuProps: { style: { maxHeight: 500 } } }}
              >
                <MenuItem value={0}>
                  ÚNICA
                </MenuItem>
                <MenuItem value={1}>
                  1ª
                </MenuItem>
                <MenuItem value={2}>
                  2ª
                </MenuItem>
              </TextField>
              {errors.praca && (
                <FormHelperText error>{errors.praca}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.name}
                fullWidth
                label="Título do leilão"
                value={name}
                name="name"
                onChange={(e) => setName(e.target.value)}
                color="secondary"
              />
              {errors.name && (
                <FormHelperText error>{errors.name}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                minRows={3}
                maxRows={10}
                label="Descrição detalhada"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                color="secondary"
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                fullWidth
                disablePortal
                options={cities.map(formatCity)}
                value={cityRef}
                onChange={(e, newValue) => setCity(newValue?.id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cidade"
                    error={!!errors.city}
                  />
                )}
              />
              {errors.city && (
                <FormHelperText error>{errors.city}</FormHelperText>
              )}
            </Grid>
            {praca !== 1 && (
              <>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                    InputProps={{ error: !!errors.startAt }}
                    label="Inicia em"
                    value={startAt}
                    onChange={setStartAt}
                    renderInput={(params) => <TextField {...params} />}
                    closeOnSelect
                    ampm={false}
                    inputFormat="dd/MM/yyyy HH:mm"
                    onError={getDateStartError}
                    components={{ OpenPickerIcon: PicketIcon }}
                    OpenPickerButtonProps={{ style: { marginRight: -4 } }}
                  />
                  {errors.startAt && (
                    <FormHelperText error>{errors.startAt}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                    InputProps={{ error: !!errors.endAt }}
                    label="Encerra em"
                    value={endAt}
                    onChange={setEndAt}
                    renderInput={(params) => <TextField {...params} />}
                    closeOnSelect
                    disablePast
                    ampm={false}
                    minDateTime={startAt}
                    inputFormat="dd/MM/yyyy HH:mm"
                    onError={getDateEndError}
                    components={{ OpenPickerIcon: PicketIcon }}
                    OpenPickerButtonProps={{ style: { marginRight: -4 } }}
                  />
                  {errors.endAt && (
                    <FormHelperText error>{errors.endAt}</FormHelperText>
                  )}
                </Grid>
              </>
            )}
            {praca === 1 && (
              <>
                <Grid item xs={12}>
                  <Typography style={{ margin: '-8px 0' }} fontSize={14} fontWeight={800} color="secondary">
                    1ª PRAÇA
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                    InputProps={{ error: !!errors.startAt }}
                    label="Inicia em"
                    value={startAt}
                    onChange={setStartAt}
                    renderInput={(params) => <TextField {...params} />}
                    closeOnSelect
                    ampm={false}
                    inputFormat="dd/MM/yyyy HH:mm"
                    onError={getDateStartError}
                    components={{ OpenPickerIcon: PicketIcon }}
                    OpenPickerButtonProps={{ style: { marginRight: -4 } }}
                  />
                  {errors.startAt && (
                    <FormHelperText error>{errors.startAt}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                    InputProps={{ error: !!errors.endFirstAt }}
                    label="Encerra em"
                    value={endFirstAt}
                    onChange={setEndFirstAt}
                    renderInput={(params) => <TextField {...params} />}
                    closeOnSelect
                    ampm={false}
                    minDateTime={startAt}
                    inputFormat="dd/MM/yyyy HH:mm"
                    onError={getDateEndFirstError}
                    components={{ OpenPickerIcon: PicketIcon }}
                    OpenPickerButtonProps={{ style: { marginRight: -4 } }}
                  />
                  {errors.endFirstAt && (
                    <FormHelperText error>{errors.endFirstAt}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{ margin: '-8px 0' }} fontSize={14} fontWeight={800} color="secondary">
                    2ª PRAÇA
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                    InputProps={{ error: !!errors.startSecondAt }}
                    label="Inicia em"
                    value={startSecondAt}
                    onChange={setStartSecondAt}
                    renderInput={(params) => <TextField {...params} />}
                    closeOnSelect
                    ampm={false}
                    minDateTime={endFirstAt}
                    inputFormat="dd/MM/yyyy HH:mm"
                    onError={getDateStartSecondError}
                    components={{ OpenPickerIcon: PicketIcon }}
                    OpenPickerButtonProps={{ style: { marginRight: -4 } }}
                  />
                  {errors.startSecondAt && (
                    <FormHelperText error>{errors.startSecondAt}</FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                    InputProps={{ error: !!errors.endAt }}
                    label="Encerra em"
                    value={endAt}
                    onChange={setEndAt}
                    renderInput={(params) => <TextField {...params} />}
                    closeOnSelect
                    disablePast
                    ampm={false}
                    minDateTime={startSecondAt}
                    inputFormat="dd/MM/yyyy HH:mm"
                    onError={getDateEndError}
                    components={{ OpenPickerIcon: PicketIcon }}
                    OpenPickerButtonProps={{ style: { marginRight: -4 } }}
                  />
                  {errors.endAt && (
                    <FormHelperText error>{errors.endAt}</FormHelperText>
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                error={errors.value}
                fullWidth
                label="Valor total"
                value={numberToCurrency(value)}
                onChange={handleCurrency(setValue)}
                color="secondary"
                InputProps={{
                  startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                }}
              />
              {errors.value && (
                <FormHelperText error>{errors.value}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container wrap="nowrap" alignItems="center">
                <TextField
                  error={errors.link}
                  fullWidth
                  label="Link do leilão"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  color="secondary"
                />
                <Tooltip title="Ver lote no site" placement="top-end">
                  <Button variant="contained" color="primary" onClick={() => window.open(link)} sx={{ minWidth: 56, ml: 2, p: 2 }}>
                    <ExternalLink />
                  </Button>
                </Tooltip>
              </Grid>
              {errors.link && (
                <FormHelperText error>{errors.link}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="column" wrap="nowrap">
                <Typography color="secondary" fontWeight={800}>
                  IMAGENS
                </Typography>
                <FormHelperText>
                  Selecione uma das imagens do leilão para ser a imagem de capa
                </FormHelperText>
                <Swiper
                  slidesPerView={4}
                  spaceBetween={16}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  style={{ width: '100%', height: 100, margin: '8px 0' }}
                >
                  {images.map((img, i) => (
                    <SwiperSlide>
                      <Grid
                        container
                        sx={{
                          height: 100,
                          position: 'relative',
                          cursor: 'pointer',
                          border: cover === img ? '2px solid' : 'none',
                          borderColor: 'primary.main',
                          borderRadius: '8px',
                        }}
                        onClick={() => setCover(img)}
                      >
                        <img
                          src={typeof img === 'object' ? URL.createObjectURL(img) : img}
                          alt="Imagem do leilão"
                        />
                        <IconButton
                          size="small"
                          sx={{
                            position: 'absolute',
                            backgroundColor: 'primary.main',
                            top: cover === img ? -1 : 0,
                            right: cover === img ? -6 : -5,
                            '&:hover': { backgroundColor: 'primary.light' },
                          }}
                          onClick={() => removeImage(i)}
                        >
                          <X size={16} />
                        </IconButton>
                      </Grid>
                    </SwiperSlide>
                  ))}
                  <SwiperSlide>
                    <Button variant="outlined" component="label" sx={styles.imagesInput}>
                      <Plus size={36} />
                      <input
                        hidden
                        accept="image/*"
                        multiple
                        type="file"
                        onChange={(e) => setImages([...images, ...Array.from(e.target.files)])}
                      />
                    </Button>
                  </SwiperSlide>
                </Swiper>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{
              padding: '16px 0 32px', position: 'sticky', bottom: 0, background: '#0D0D0D', zIndex: 10,
            }}
          >
            <Grid item>
              {isApprove ? (
                <Tooltip title="Reprovar leilão">
                  <Button
                    disabled={loading}
                    size="large"
                    variant="contained"
                    color="error"
                    onClick={() => setOpenDelete(true)}
                    sx={{ minWidth: 0, padding: '11px' }}
                  >
                    <Trash2 size={20} />
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  disabled={loading}
                  size="large"
                  variant="outlined"
                  color="secondary"
                  onClick={handleClose}
                >
                  Cancelar
                </Button>
              )}
            </Grid>
            <Grid item>
              {isApprove ? (
                <LoadingButton
                  type="submit"
                  loading={loading}
                  size="large"
                  variant="contained"
                  color="success"
                >
                  <Check style={{ marginRight: 8 }} />
                  Aprovar e ver próximo
                </LoadingButton>
              ) : (
                <LoadingButton
                  type="submit"
                  loading={loading}
                  size="large"
                  variant="contained"
                  color="primary"
                >
                  {auction ? 'Salvar' : 'Adicionar'}
                </LoadingButton>
              )}
            </Grid>
          </Grid>

          <DeleteDialog
            open={!!openDelete}
            title={`Tem certeza que deseja reprovar o leilão de ${auction?.auctionName}? Ele será deletado da nossa base de dados.`}
            onDelete={async () => {
              await axios.post(
                'https://us-central1-clube-do-leilao.cloudfunctions.net/auction-delete',
                auction,
              );
              setOpenDelete(false);
              if (isApprove) next();
              else onClose();
            }}
            onClose={() => setOpenDelete(false)}
          />
        </form>
      </Grid>
    </Drawer>
  );
}

FormAuction.propTypes = {
  isApprove: PropTypes.bool.isRequired,
  auction: PropTypes.shape({
    idAuction: PropTypes.number,
    categoryID: PropTypes.number,
    companyID: PropTypes.number,
    auctionName: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    cover: PropTypes.string,
    instance: PropTypes.number,
    cityId: PropTypes.string,
    startAt: PropTypes.string,
    endFirstAt: PropTypes.string,
    startSecondAt: PropTypes.string,
    endAt: PropTypes.string,
    description: PropTypes.string,
    value: PropTypes.number,
    link: PropTypes.string,
    approved: PropTypes.bool,
  }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      idCategory: PropTypes.number,
      name: PropTypes.string,
      icon: PropTypes.string,
      subCategoryOf: PropTypes.number,
      order: PropTypes.number,
    }),
  ).isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      idCompany: PropTypes.number,
      name: PropTypes.string,
      site: PropTypes.string,
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  next: PropTypes.func.isRequired,
};

FormAuction.defaultProps = {
  auction: null,
};

export default FormAuction;
