import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import Loading from '@components/loading';

import { useAuthContext } from '@contexts/auth';

import LogoExt from '@assets/logo-ext.png';

import {
  Tag, Package, Briefcase, Lock, Smartphone, ChevronsLeft, ChevronsRight, BarChart,
} from 'react-feather';

import { Button } from '@mui/material';
import styles from './style';

const formatUrl = (url) => `https://${url}`.replace('https://http', 'http');

const getInitials = (name) => {
  if (name) {
    const splitted = name.split(' ');
    if (splitted.length > 1) {
      return (splitted[0][0] + splitted.reverse()[0][0]).toUpperCase();
    }

    return (splitted[0][0] + splitted[0][1]).toUpperCase();
  }
  return null;
};

const menuOpts = [
  {
    id: 'dashboard',
    name: 'Dashboard',
    link: '/',
    icon: BarChart,
    show: (user) => user.owner || user.companyId || user.permissions?.dashboard?.read,
  },
  {
    id: 'users',
    name: 'Usuários',
    link: '/users',
    icon: Smartphone,
    show: (user) => !user.companyId && (user.owner || user.permissions?.usersApp?.read),
  },
  {
    id: 'auctions',
    name: 'Leilões',
    link: '/auctions',
    icon: Package,
    show: (user) => user.owner || user.companyId || user.permissions?.auctions?.read,
  },
  {
    id: 'categories',
    name: 'Categorias',
    link: '/categories',
    icon: Tag,
    show: (user) => !user.companyId && (user.owner || user.permissions?.categories?.read),
  },
  {
    id: 'companies',
    name: 'Leiloeiros',
    link: '/companies',
    icon: Briefcase,
    show: (user) => !user.companyId && (user.owner || user.permissions?.companies?.read),
  },
  {
    id: 'people',
    name: 'Admins',
    link: '/people',
    icon: Lock,
    show: (user) => !user.companyId && (user.owner || user.permissions?.admins?.read),
  },
];

function Home() {
  const location = useLocation();
  const navigate = useNavigate();

  const [authState] = useAuthContext();

  const [company, setCompany] = useState(null);
  const [expanded, setExpanded] = useState(localStorage.getItem('sidebar-expanded') === 'true');

  useEffect(() => {
    if (!authState.user && !authState.loading) navigate('/auth');
  }, [authState, navigate]);

  useEffect(() => {
    const getCompany = async () => {
      const { data } = await axios.get(
        `https://us-central1-clube-do-leilao.cloudfunctions.net/company-show?companyId=${authState.user?.companyId}`,
      );
      setCompany(data);
    };

    if (authState.user?.companyId) getCompany();
  }, [authState]);

  if (authState.loading || !authState.user) return <Loading />;

  const handleSidebar = () => {
    localStorage.setItem('sidebar-expanded', !expanded);
    setExpanded(!expanded);
  };

  return (
    <Grid container wrap="nowrap" alignItems="flex-start">
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        sx={!expanded ? { ...styles.sidebar, maxWidth: 104, minWidth: 0 } : styles.sidebar}
      >
        <Grid container direction="column" alignItems="center" justifyContent="center">
          <img
            src={LogoExt}
            alt="Logo do Clube do Leilão"
            style={styles.logo(expanded)}
          />
          {company && (
            <Grid container alignItems="center" justifyContent="center" wrap="nowrap" sx={styles.company}>
              <img
                src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${formatUrl(company.site)}`}
                alt={company.name}
                style={{ width: 18, height: 18, marginRight: 16 }}
              />
              <Typography color="textSecondary" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {company.name}
              </Typography>
            </Grid>
          )}

          <Button sx={styles.expandButton(expanded)} onClick={handleSidebar}>
            {expanded ? <ChevronsLeft /> : <ChevronsRight />}
          </Button>
        </Grid>
        <List sx={{ width: '100%' }}>
          {menuOpts.filter((m) => m.show(authState.user)).map((opt) => {
            const isActivated = location.pathname === opt.link;
            return (
              <ListItem key={opt.id} disablePadding>
                <ListItemButton
                  sx={{ ...styles.menuItem, ...(isActivated ? styles.activedItem : {}) }}
                  onClick={() => navigate(opt.link)}
                >
                  <ListItemIcon>
                    <opt.icon color={isActivated ? '#F3B035' : '#fff'} style={{ opacity: 0.7, strokeWidth: 3 }} />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ color: isActivated ? 'secondary.main' : 'text.secondary' }}
                    primaryTypographyProps={{ style: { fontWeight: 700 } }}
                    primary={opt.name}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <Grid container>
          <ListItem disablePadding>
            <ListItemButton sx={styles.profile} onClick={() => navigate('/profile')}>
              <ListItemAvatar>
                <Avatar
                  alt={authState.user?.displayName}
                  src={authState.user?.photoURL}
                  sx={styles.avatar}
                >
                  {getInitials(authState.user?.displayName)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                sx={{ color: 'text.secondary' }}
                primary={authState.user?.displayName?.split(' ')[0]}
              />
            </ListItemButton>
          </ListItem>
        </Grid>
      </Grid>
      <Grid
        container
        item
        sx={{
          padding: 4, paddingLeft: 0, maxHeight: '100vh', overflow: 'auto',
        }}
      >
        <Outlet />
      </Grid>
    </Grid>
  );
}

export default Home;
