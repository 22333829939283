import { alpha } from '@mui/material/styles';

export default {
  carouselImage: {
    height: 300,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 1,
  },

  imagesInput: {
    height: 100,
    width: '100%',
    borderStyle: 'dashed',
    borderWidth: 3,
    '&:hover': {
      borderWidth: 3,
    },
  },

  addItem: (theme) => ({
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    padding: '12px 16px',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.4),
    },
  }),

  reportContainer: {
    border: '1px solid #222',
    borderRadius: 1,
    padding: 2,
    marginBottom: 2,
  },

  reportAvatar: {
    width: 20,
    height: 20,
    marginRight: 2,
  },

  reportName: {
    opacity: 0.7,
    fontSize: 14,
  },

  reportDescription: {
    marginTop: 1,
  },
};
