import React, { useState, useEffect } from 'react';
import validator from 'validator';

import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { AlertCircle, Eye, EyeOff } from 'react-feather';

import AuthController from '@controllers/auth';
import { useAuthContext } from '@contexts/auth';

import LogoExt from '@assets/logo-ext.png';

import styles from './style';

function SignIn() {
  const [authState, dispatch] = useAuthContext();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ });

  useEffect(() => {
    if (authState.user) window.location.href = '/';
  }, [authState]);

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const errs = {};

    if (!email) errs.email = 'Digite seu e-mail';
    else if (!validator.isEmail(email)) errs.email = 'Digite um e-mail válido';

    if (!password) errs.password = 'Digite sua senha';

    setErrors(errs);
    if (Object.keys(errs).length > 0) {
      setLoading(false);
      return;
    }

    await AuthController(dispatch).signIn(email, password);
    setLoading(false);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={styles.container}
    >
      <Grid container justifyContent="center" style={{ marginBottom: 64 }}>
        <img src={LogoExt} alt="Logo do Clube do Leilão" />
      </Grid>
      <form onSubmit={submit}>
        <Grid container spacing={3}>
          {authState.signError && (
            <Grid item xs={12}>
              <Grid container alignItems="center" wrap="nowrap" sx={styles.errorContainer}>
                <AlertCircle size={18} style={{ color: '#fff', margin: '0 8px', minWidth: 24 }} />
                <Typography style={{ marginLeft: 8 }}>
                  {authState.signError}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={errors.email}
              label="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {errors.email && (
              <FormHelperText error sx={styles.errorMessage}>{errors.email}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={errors.password}
              type={showPassword ? 'text' : 'password'}
              label="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <EyeOff /> : <Eye />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Grid item container justifyContent="space-between" alignItems="center" sx={styles.errorMessage}>
              {errors.password ? (
                <FormHelperText error>{errors.password}</FormHelperText>
              ) : <div />}
              <Typography sx={styles.forgetPassword}>
                Esqueceu sua senha?
              </Typography>
            </Grid>
          </Grid>
          <Grid item container justifyContent="center">
            <LoadingButton loading={loading} variant="contained" type="submit" sx={styles.submit}>
              <Grid container alignItems="center">
                Entrar
              </Grid>
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

export default SignIn;
