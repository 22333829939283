export default {
  sidebar: {
    position: 'relative',
    maxWidth: 285,
    width: 285,
    minWidth: 285,
    height: 'calc(100vh - 64px)',
    margin: 4,
    borderRadius: 1,
    border: '1px solid #222',
    padding: 2,
    overflow: 'hidden',
    transition: 'max-width 0.2s, min-width 0.2s',
  },

  logo: (expanded) => ({
    width: !expanded ? 72 : 'inherit',
    marginTop: 32,
    transition: 'width 0.2s, margin-bottom 0.2s',
    marginBottom: !expanded ? 104 : 0,
  }),

  expandButton: (expanded) => ({
    minWidth: 0,
    position: 'absolute',
    top: expanded ? 16 : 104,
    right: expanded ? 16 : 32,
    transition: 'top 0.2s, right 0.2s',
  }),

  company: {
    border: '1px solid #222',
    borderRadius: 1,
    padding: 2,
    marginTop: 4,
  },

  menuItem: {
    padding: '12px 24px',
    borderRadius: 1,
    marginBottom: 0.5,
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: 'rgb(243 176 53 / 5%)',
    },
  },

  activedItem: {
    backgroundColor: 'rgb(243 176 53 / 10%)',
    '&:hover': {
      backgroundColor: 'rgb(243 176 53 / 10%)',
    },
  },

  profile: {
    padding: '12px 16px',
    borderRadius: 1,
    marginBottom: 0.5,
    backgroundColor: 'rgb(60 60 200 / 10%)',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: 'rgb(60 60 200 / 20%)',
    },
  },
  avatar: {
    backgroundColor: 'primary.main',
    color: 'background.default',
    borderColor: 'text.primary',
    borderWidth: '2px',
  },
};
