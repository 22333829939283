/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useAuthContext } from '@contexts/auth';

import { ChevronDown, X } from 'react-feather';

import {
  Accordion, AccordionDetails, AccordionSummary, Autocomplete, Chip,
} from '@mui/material';

import { BRAZIL_STATES } from '@consts';
import useCities from '../../hooks/useCities';

// import styles from './style';

const pracas = ['Única', '1ª praça', '2ª praça'];
const formatCity = (city) => ({ id: city.idCity, label: city.name });

const handleCurrency = (set) => (e) => {
  const { value: string } = e.target;

  const cleanString = string
    .replaceAll('.', '')
    .replaceAll(',', '')
    .replaceAll('e', '')
    .replaceAll('+', '')
    .replaceAll('-', '');
  const value = `${cleanString.slice(0, -2)}.${cleanString.slice(-2)}`;
  set(parseFloat(value));
};

const numberToCurrency = (number) => {
  if (!number) return '0,00';
  return number.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
};

function FilterAuction({
  open, filter, onChange, categories, companies,
}) {
  const [authState] = useAuthContext();

  const [selCategories, setSelCategories] = useState([]);
  const [company, setCompany] = useState('');
  const [search, setSearch] = useState('');
  const [praca, setPraca] = useState([]);
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [valueFrom, setValueFrom] = useState('');
  const [valueTo, setValueTo] = useState('');

  const states = BRAZIL_STATES.map((s) => ({ value: s.code, label: s.name }));
  const cities = useCities();
  const [cityRef, setCityRef] = useState(null);

  useEffect(() => {
    if (city && cities.length > 0) {
      const selected = cities.find((item) => item.idCity === city);
      setCityRef(selected ? formatCity(selected) : null);
    }
  }, [city, cities]);

  useEffect(() => {
    if (authState.user) setCompany(authState.user.companyId || '');
  }, [authState.user]);

  useEffect(() => {
    const urlParams = new URLSearchParams(`?${filter}`);
    setSelCategories(urlParams.get('categories')?.split(',').map((cId) => parseInt(cId, 10)) || []);
    setCompany(urlParams.get('companyId') || '');
    setSearch(urlParams.get('name') || '');
    setPraca(urlParams.get('instance')?.split(',').map((cId) => parseInt(cId, 10)) || []);
    setCity(urlParams.get('city') || '');
    setState(urlParams.get('state') || '');
    setValueFrom(urlParams.get('priceMin') ? parseInt(urlParams.get('priceMin'), 10) : '');
    setValueTo(urlParams.get('priceMax') ? parseInt(urlParams.get('priceMax'), 10) : '');
  }, [filter]);

  const submit = async (e) => {
    e.preventDefault();

    if (!!valueFrom && !!valueTo && valueFrom > valueTo) return;

    const newFilter = [];
    if (search) newFilter.push(`name=${search}`);
    if (selCategories.length > 0) newFilter.push(`categories=${selCategories.join(',')}`);
    if (city) newFilter.push(`city=${city}`);
    if (state) newFilter.push(`state=${state}`);
    if (valueFrom) newFilter.push(`priceMin=${valueFrom}`);
    if (valueTo) newFilter.push(`priceMax=${valueTo}`);
    if (praca.length > 0) newFilter.push(`instance=${praca.join(',')}`);
    if (company) newFilter.push(`companyId=${company}`);

    onChange(newFilter.length === 0 ? [] : newFilter.join('&'));
  };

  const handleSelectPraca = (value) => {
    const index = praca.indexOf(value);
    const newPraca = [...praca];

    if (index !== -1) newPraca.splice(index, 1);
    else newPraca.push(value);

    setPraca(newPraca);
  };

  const handleSelectCategory = (cat) => {
    const index = selCategories.indexOf(cat.idCategory);
    const newSelCategories = [...selCategories];

    if (index !== -1) newSelCategories.splice(index, 1);
    else newSelCategories.push(cat.idCategory);

    setSelCategories(newSelCategories);
  };

  const handleSelectAll = (main, selectedAll) => {
    const subCategories = categories.filter((c) => c.subCategoryOf === main.idCategory);
    const newSelCategories = [...selCategories];

    const indexMain = newSelCategories.indexOf(main.idCategory);
    if (indexMain !== -1 && selectedAll) newSelCategories.splice(indexMain, 1);
    else if (indexMain === -1 && !selectedAll) newSelCategories.push(main.idCategory);

    subCategories.forEach((c) => {
      const index = newSelCategories.indexOf(c.idCategory);
      if (index !== -1 && selectedAll) newSelCategories.splice(index, 1);
      else if (index === -1 && !selectedAll) newSelCategories.push(c.idCategory);
    });

    setSelCategories(newSelCategories);
  };

  return (
    <Drawer
      anchor="right"
      open={!!open}
      onClose={() => onChange(filter)}
      PaperProps={{ sx: { backgroundImage: 'none' } }}
    >
      <Grid container direction="column" sx={{ width: '500px', padding: 4, paddingBottom: 0 }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Typography fontWeight={800} fontSize={20}>
            Filtrar leilões
          </Typography>
          <IconButton onClick={() => onChange(filter)}>
            <X />
          </IconButton>
        </Grid>
        <form onSubmit={submit} style={{ width: '100%' }}>
          <Grid container spacing={3} sx={{ flexGrow: 1, padding: '32px 0' }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled={!!authState.user?.companyId}
                select
                label="Leiloeiro"
                value={company}
                color="secondary"
                onChange={(e) => setCompany(e.target.value)}
                InputProps={{
                  endAdornment: company && (
                    <InputAdornment position="end">
                      <IconButton sx={{ mr: 2 }} onClick={() => setCompany('')}>
                        <X size={18} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              >
                {companies?.map((item) => (
                  <MenuItem key={item.idCompany} value={item.idCompany}>
                    <Grid container alignItems="center">
                      <img
                        src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${item.site}`}
                        alt={item.name}
                        style={{ marginRight: 16 }}
                      />
                      {item.name}
                    </Grid>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                placeholder="Pesquisar por leilões com..."
                value={search}
                name="name"
                onChange={(e) => setSearch(e.target.value)}
                color="secondary"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography color="secondary" fontWeight={800} sx={{ mb: 2, mt: 2 }}>CATEGORIAS</Typography>
              {categories?.filter((c) => !c.subCategoryOf).map((main) => {
                const subCategories = categories.filter((c) => c.subCategoryOf === main.idCategory);
                const selectedAll = selCategories.some((cId) => cId === main.idCategory);

                return (
                  <Accordion key={main.id} sx={{ backgroundImage: 'none', '&:before': { content: 'none' } }}>
                    <AccordionSummary expandIcon={<ChevronDown />}>
                      <Typography>{main.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container>
                        <Chip
                          label="TODOS"
                          variant={selectedAll ? 'outlined' : 'filled'}
                          color={selectedAll ? 'secondary' : 'default'}
                          onClick={() => handleSelectAll(main, selectedAll)}
                          sx={{ m: 0.5, borderRadius: 1 }}
                        />
                        {subCategories.map((cat) => {
                          const selected = selCategories.some((cId) => cId === cat.idCategory);

                          return (
                            <Chip
                              key={cat.idCategory}
                              label={cat.name}
                              variant={selected ? 'outlined' : 'filled'}
                              color={selected ? 'secondary' : 'default'}
                              onClick={() => handleSelectCategory(cat)}
                              sx={{ m: 0.5, borderRadius: 1 }}
                            />
                          );
                        })}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Grid>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <Typography color="secondary" fontWeight={800} sx={{ mb: 2 }}>PRAÇA</Typography>
              <Grid container>
                {pracas.map((label, value) => (
                  <Chip
                    key={label}
                    label={label}
                    variant={praca.indexOf(value) !== -1 ? 'outlined' : 'filled'}
                    color={praca.indexOf(value) !== -1 ? 'secondary' : 'default'}
                    onClick={() => handleSelectPraca(value)}
                    sx={{ m: 0.5, borderRadius: 1 }}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                select
                label="Estado"
                value={state}
                color="secondary"
                onChange={(e) => setState(e.target.value)}
                SelectProps={{ MenuProps: { style: { maxHeight: 500 } } }}
              >
                {states?.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Autocomplete
                fullWidth
                disablePortal
                color="secondary"
                disabled={!state}
                options={cities.filter((item) => item.state === state).map(formatCity)}
                value={cityRef}
                onChange={(e, newValue) => setCity(newValue?.id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cidade"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={!!valueFrom && !!valueTo && valueFrom > valueTo}
                fullWidth
                label="Valor mínimo"
                value={numberToCurrency(valueFrom)}
                onChange={handleCurrency(setValueFrom)}
                color="secondary"
                InputProps={{
                  startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={!!valueFrom && !!valueTo && valueFrom > valueTo}
                fullWidth
                label="Valor máximo"
                value={numberToCurrency(valueTo)}
                onChange={handleCurrency(setValueTo)}
                color="secondary"
                InputProps={{
                  startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                }}
              />
            </Grid>
            {!!valueFrom && !!valueTo && valueFrom > valueTo && (
              <Grid item xs={12}>
                <FormHelperText error>
                  O valor inicial precisa ser menor que o valor final
                </FormHelperText>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{
              padding: '16px 0 32px', position: 'sticky', bottom: 0, background: '#0D0D0D', zIndex: 10,
            }}
          >
            <Grid item>
              <Button
                size="large"
                variant="outlined"
                color="secondary"
                onClick={() => onChange('')}
              >
                LIMPAR
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="primary"
              >
                APLICAR
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Drawer>
  );
}

FilterAuction.propTypes = {
  filter: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      idCategory: PropTypes.number,
      name: PropTypes.string,
      icon: PropTypes.string,
      subCategoryOf: PropTypes.number,
      order: PropTypes.number,
    }),
  ).isRequired,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      idCompany: PropTypes.number,
      name: PropTypes.string,
      site: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

FilterAuction.defaultProps = {
  filter: '',
};

export default FilterAuction;
