import React, { useState } from 'react';
import { useSWRConfig } from 'swr';
import PropTypes from 'prop-types';
import axios from 'axios';

import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import DeleteDialog from '@components/dialog-delete';
import {
  Plus, ChevronDown, ChevronUp, CornerDownRight, Edit2, Trash2, Repeat,
} from 'react-feather';

import MigrateDialog from './migrate';

const style = {
  header: {
    position: 'sticky',
    top: -1,
    zIndex: 1,
    backgroundColor: '#0f1017',
  },
};

function Row({
  row, categories, onAdd, onEdit, canEdit,
}) {
  const { mutate } = useSWRConfig();
  const [open, setOpen] = useState(true);

  const [onMigrate, setOnMigrate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const subCategories = categories.filter((c) => c.subCategoryOf === row.idCategory);

  return (
    <>
      <TableRow
        sx={style.header}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <ChevronUp /> : <ChevronDown />}
          </IconButton>
        </TableCell>
        <TableCell style={{ fontWeight: 800 }}>
          {row.name}
        </TableCell>
        <TableCell align="center" style={{ fontWeight: 800 }}>
          {row.totalAuctions}
        </TableCell>
        <TableCell align="center" style={{ fontWeight: 800 }}>
          {row.totalAlerts}
        </TableCell>
        {canEdit && (
          <TableCell>
            <Grid container wrap="nowrap" justifyContent="flex-end">
              {row.idCategory > 4 && (
                <Tooltip placement="left" title="Migrar categoria">
                  <IconButton
                    sx={{ marginRight: 1 }}
                    onClick={() => setOnMigrate(row)}
                  >
                    <Repeat size={18} />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton sx={{ marginRight: 1 }} onClick={() => onEdit(row)}>
                <Edit2 size={18} />
              </IconButton>
              <Tooltip
                placement="left"
                title={(subCategories.length > 0 || row.totalAuctions > 0)
                  && 'Você não pode excluir uma categoria que tenha leilões ou sub-categorias vinculados'}
              >
                <span>
                  <IconButton
                    disabled={subCategories.length > 0 || row.totalAuctions > 0}
                    onClick={() => setOpenDelete(row)}
                  >
                    <Trash2 size={18} />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table aria-label="subcategories">
              <TableBody>
                {subCategories.map((subCat) => (
                  <TableRow key={subCat.idCategory}>
                    <TableCell align="right" style={{ width: 34 }}>
                      <CornerDownRight size={16} style={{ opacity: 0.5 }} />
                    </TableCell>
                    <TableCell>
                      {subCat.name}
                    </TableCell>
                    <TableCell align="center" style={{ width: 110 }}>
                      {subCat.totalAuctions}
                    </TableCell>
                    <TableCell align="center" style={{ width: 110 }}>
                      {subCat.totalAlerts}
                    </TableCell>
                    {canEdit && (
                      <TableCell style={{ width: 108 }}>
                        <Grid container wrap="nowrap" justifyContent="flex-end">
                          <Tooltip placement="left" title="Migrar categoria">
                            <IconButton
                              sx={{ marginRight: 1 }}
                              onClick={() => setOnMigrate(subCat)}
                            >
                              <Repeat size={18} />
                            </IconButton>
                          </Tooltip>
                          <IconButton sx={{ marginRight: 1 }} onClick={() => onEdit(subCat)}>
                            <Edit2 size={18} />
                          </IconButton>
                          <Tooltip
                            placement="left"
                            title={subCat.totalAuctions > 0
                              && 'Você não pode excluir uma categoria que tenha leilões vinculados'}
                          >
                            <span>
                              <IconButton
                                disabled={subCat.totalAuctions > 0}
                                onClick={() => setOpenDelete(subCat)}
                              >
                                <Trash2 size={18} />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Grid>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                {canEdit && (
                  <TableRow>
                    <TableCell style={{ width: 34, border: 'none' }} />
                    <TableCell colSpan={3} style={{ border: 'none' }}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => onAdd(row.idCategory)}
                      >
                        <Plus size={20} style={{ marginRight: 8 }} />
                        Adicionar sub-categoria
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>

      <DeleteDialog
        open={!!openDelete}
        title={`Tem certeza que deseja deletar a categoria ${openDelete.name}?`}
        onDelete={async () => {
          await axios.post(
            'https://us-central1-clube-do-leilao.cloudfunctions.net/categories-delete',
            openDelete,
          );
          setOpenDelete(false);
          mutate('https://us-central1-clube-do-leilao.cloudfunctions.net/categories-index?withTotal=true');
        }}
        onClose={() => setOpenDelete(false)}
      />

      <MigrateDialog
        categories={categories}
        category={onMigrate}
        open={!!onMigrate}
        onClose={() => setOnMigrate(false)}
      />
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    idCategory: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    totalAuctions: PropTypes.number,
    totalAlerts: PropTypes.number,
  }).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      idCategory: PropTypes.number.isRequired,
      name: PropTypes.string,
    }),
  ).isRequired,
  onEdit: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default Row;
