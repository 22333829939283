import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#3C3CC8',
    },
    secondary: {
      main: '#F3B035',
    },
    background: {
      default: '#0D0D0D',
      paper: '#0D0D0D',
    },
  },
  shape: { borderRadius: 8 },
});
