import { signInWithEmailAndPassword, browserLocalPersistence, setPersistence } from 'firebase/auth';
import {
  getDoc, doc, collection, query, where, getDocs,
} from 'firebase/firestore';
import { auth, db } from '@services/firebase';

const errorMessage = {
  'auth/wrong-password': 'E-mail e senha não conferem',
  'auth/too-many-requests': 'Muitas tentativas erradas! Crie uma nova senha ou tente novamente mais tarde',
};

export default function controller(dispatch) {
  return {
    signIn: async (email, password) => {
      await setPersistence(auth, browserLocalPersistence)
        .then(() => signInWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            const { user } = userCredential;
            const docSnap = await getDoc(doc(db, 'users', user.uid));
            const { companyId } = docSnap.data();
            dispatch({ user: { ...user, companyId }, signError: null });
          })
          .catch((error) => {
            dispatch((s) => ({ ...s, signError: errorMessage[error.code] || error.code }));
          }))
        .catch((error) => {
          dispatch((s) => ({ ...s, signError: errorMessage[error.code] || error.code }));
        });
    },

    getUserByCreatePassword: async (token) => {
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('createPassword', '==', token));
      console.log(q);
      const snap = await getDocs(q);
      console.log(snap);
      return snap.size > 0 ? ({ ...snap.docs[0].data(), id: snap.docs[0].id }) : null;
    },
  };
}
