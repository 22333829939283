import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import axios from 'axios';

import { db } from '@services/firebase';
import { doc, updateDoc } from 'firebase/firestore';

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import {
  X, Eye, Edit2, Package, Tag, Briefcase, Users, BarChart, Smartphone,
} from 'react-feather';

const defaultPermissions = {
  dashboard: { read: true, edit: false },
  users: { read: false, edit: false },
  auctions: { read: true, edit: true },
  categories: { read: true, edit: true },
  companies: { read: true, edit: true },
  admins: { read: false, edit: false },
  usersApp: { read: true, edit: true },
};

const permissionsOpt = [
  { id: 'dashboard', label: 'Dashboard', icon: BarChart },
  { id: 'usersApp', label: 'Usuários do app', icon: Smartphone },
  { id: 'auctions', label: 'Leilões', icon: Package },
  { id: 'categories', label: 'Categorias', icon: Tag },
  { id: 'companies', label: 'Leiloeiros', icon: Briefcase },
  { id: 'admins', label: 'Admins', icon: Users },
];

function FormUser({ open, user, onClose }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [permissions, setPermissions] = useState(defaultPermissions);
  const [errors, setErrors] = useState({ name: false, email: false });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setPermissions(user.permissions);
    }
  }, [user]);

  const handleClose = () => {
    if (!loading) {
      setName('');
      setEmail('');
      setPermissions(defaultPermissions);
      setErrors({ name: false, email: false });
      onClose();
    }
  };

  const handlePermission = (id, action) => () => {
    const value = permissions[id] ? !permissions[id][action] : true;
    if (action === 'read' && !value) {
      setPermissions((p) => ({ ...p, [id]: { read: false, edit: false } }));
    } else if (action === 'edit' && value) {
      setPermissions((p) => ({ ...p, [id]: { read: true, edit: true } }));
    } else {
      setPermissions((p) => ({ ...p, [id]: { ...p[id], [action]: value } }));
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const errs = { name: false, email: false };

    if (!name) errs.name = 'O admin precisa ter um nome';

    if (!email) errs.email = 'O admin precisa ter um e-mail de acesso';
    else if (!validator.isEmail(email)) {
      errs.email = 'Precisa ser um e-mail válido';
    }

    setErrors(errs);

    if (errs.name || errs.email) {
      setLoading(false);
      return;
    }

    const data = { name, email, permissions };

    try {
      if (!user) {
        await axios.post('https://us-central1-clube-do-leilao.cloudfunctions.net/user-store', data);
      } else {
        const userRef = doc(db, 'users', user.id);
        await updateDoc(userRef, { permissions });
      }
      setLoading(false);
      handleClose();
    } catch (error) {
      if (error.response?.data === 'auth/email-already-exists') {
        setLoading(false);
        setErrors({ ...errs, email: 'Este e-mail já está cadastrado' });
      }
    }
  };

  return (
    <Drawer
      anchor="right"
      open={!!open}
      onClose={handleClose}
      PaperProps={{ sx: { backgroundImage: 'none' } }}
    >
      <Grid container direction="column" sx={{ width: '500px', padding: 4 }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Typography fontWeight={800} fontSize={20}>
            {user ? `Editar ${user.name}` : 'Adicionar admin'}
          </Typography>
          <IconButton onClick={handleClose}>
            <X />
          </IconButton>
        </Grid>
        <form onSubmit={submit}>
          <Grid container direction="column" spacing={4} sx={{ flexGrow: 1, padding: '32px 0' }}>
            <Grid item>
              <TextField
                disabled={!!user}
                error={errors.name}
                fullWidth
                label="Nome completo"
                value={name}
                name="name"
                onChange={(e) => setName(e.target.value)}
                color="secondary"
              />
              {errors.name && (
                <FormHelperText error>{errors.name}</FormHelperText>
              )}
            </Grid>
            <Grid item>
              <Grid container alignItems="center" wrap="nowrap">
                <Grid container>
                  <TextField
                    disabled={!!user}
                    error={errors.email}
                    fullWidth
                    label="E-mail"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    color="secondary"
                  />
                  {errors.email && (
                    <FormHelperText error>{errors.email}</FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container direction="column">
                <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
                  <Typography color="secondary" fontWeight={800}>PERMISSÕES</Typography>
                  <Grid item>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Tooltip title="Pode visualizar">
                        <Eye color="#F3B035" size={16} style={{ margin: '0 21px' }} />
                      </Tooltip>
                      <Tooltip title="Pode editar">
                        <Edit2 color="#F3B035" size={16} style={{ margin: '0 21px' }} />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                {permissionsOpt.map((opt) => (
                  <Grid
                    key={opt.id}
                    container
                    wrap="nowrap"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ margin: '16px 0 0' }}
                  >
                    <Grid container alignItems="center">
                      <opt.icon size={16} style={{ marginRight: 12 }} />
                      <Typography>{opt.label}</Typography>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center" wrap="nowrap">
                        <Switch
                          checked={permissions[opt.id]?.read}
                          onChange={handlePermission(opt.id, 'read')}
                        />
                        {opt.id === 'dashboard' ? <div style={{ width: 58 }} /> : (
                          <Switch
                            checked={permissions[opt.id]?.edit}
                            onChange={handlePermission(opt.id, 'edit')}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                disabled={loading}
                size="large"
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                type="submit"
                loading={loading}
                size="large"
                variant="contained"
                color="primary"
              >
                {user ? 'Salvar' : 'Adicionar'}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Drawer>
  );
}

FormUser.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    permissions: PropTypes.shape({}),
  }),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

FormUser.defaultProps = {
  user: null,
};

export default FormUser;
