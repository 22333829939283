import React from 'react';

import SignIn from '@app/auth';
import AdminCreatePassword from '@app/auth/create-password';

import CreatePassword from '@app/create-password';

import Home from '@app/home';
import Auctions from '@app/auctions';
import Categories from '@app/categories';
import Companies from '@app/companies';
import People from '@app/people';
import Users from '@app/users-app';
import Profile from '@app/profile';
import Dashboard from '@app/dashboard';

export default [
  {
    path: '/create-password',
    element: <CreatePassword />,
  },
  {
    path: '/auth',
    element: <SignIn />,
  },
  {
    path: '/auth/create-password/:token',
    element: <AdminCreatePassword />,
  },
  {
    path: '/',
    element: <Home />,
    children: [
      {
        path: '/',
        element: <Dashboard />,
      },
      {
        path: '/dashboard',
        element: <Dashboard />,
      },
      {
        path: '/auctions',
        element: <Auctions />,
      },
      {
        path: '/categories',
        element: <Categories />,
      },
      {
        path: '/companies',
        element: <Companies />,
      },
      {
        path: '/people',
        element: <People />,
      },
      {
        path: '/users',
        element: <Users />,
      },
      {
        path: '/profile',
        element: <Profile />,
      },
    ],
  },
];
