import React, { useState } from 'react';
import {
  Chip,
  Grid, MenuItem, TextField, Tooltip, Typography,
} from '@mui/material';

import { Globe, Percent, Smartphone } from 'react-feather';
import auctions from './fake-data/top-auctions';

import style from './style';

function TopAuctions() {
  const [orderBy, setOrderBy] = useState('appView');

  return (
    <Grid item sx={style.card}>
      <Grid container direction="column">
        <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
          <Typography sx={(theme) => ({ ...style.title(theme), mb: 0 })}>LEILÕES</Typography>
          <TextField
            select
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value)}
            sx={style.selectOrderBy}
          >
            <MenuItem value="appView">VISITAS NO APP</MenuItem>
            <MenuItem value="siteView">VISITAS NO SITE</MenuItem>
            <MenuItem value="conversion">CONVERSÃO</MenuItem>
          </TextField>
        </Grid>
        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ mt: 4 }}>
          <Grid container alignItems="center" justifyContent="flex-end" sx={{ pr: 2, mb: 2 }}>
            <Grid container justifyContent="center" sx={{ minWidth: 50, width: 50, m: '0 4px' }}>
              <Tooltip title="Visitas no app">
                <Smartphone color="#F3B035" size={18} />
              </Tooltip>
            </Grid>
            <Grid container justifyContent="center" sx={{ minWidth: 50, width: 50, m: '0 4px' }}>
              <Tooltip title="Visitas no site">
                <Globe color="#F3B035" size={18} />
              </Tooltip>
            </Grid>
            <Grid container justifyContent="center" sx={{ minWidth: 50, width: 50, m: '0 4px' }}>
              <Tooltip title="Conversão">
                <Percent color="#F3B035" size={18} />
              </Tooltip>
            </Grid>
          </Grid>
          {auctions.sort((a, b) => b[orderBy] - a[orderBy]).map((auction) => (
            <Grid container wrap="nowrap" alignItems="center" sx={style.cardList}>
              <Grid container wrap="nowrap" alignItems="center">
                <Typography sx={{ width: 1 }}>
                  {auction.name}
                </Typography>
                <Chip color="primary" label={auction.category} size="small" sx={{ mr: 1 }} />
                <div>
                  <Tooltip title={auction.companyName}>
                    <img
                      src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${auction.companySite}`}
                      alt={auction.companyName}
                      style={{ width: 18, height: 18, marginBottom: -4 }}
                    />
                  </Tooltip>
                </div>
              </Grid>
              <Grid container justifyContent="center" sx={{ minWidth: 50, width: 50, m: '0 4px' }}>{auction.appView}</Grid>
              <Grid container justifyContent="center" sx={{ minWidth: 50, width: 50, m: '0 4px' }}>{auction.siteView}</Grid>
              <Grid container justifyContent="center" sx={{ minWidth: 50, width: 50, m: '0 4px' }}>
                {auction.conversion}
                %
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TopAuctions;
