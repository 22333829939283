export default {
  avatar: {
    width: 120,
    height: 120,
    fontSize: '48px',
    marginRight: 5,
    backgroundColor: 'primary.main',
  },

  formAvatar: {
    backgroundColor: 'primary.main',
    color: 'background.default',
    borderColor: 'text.primary',
    borderWidth: '2px',
    marginRight: 2,
  },
};
