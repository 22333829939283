import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';

import styles from './style';

function Card({ children, style }) {
  return (
    <Grid container wrap="nowrap" direction="column" sx={{ ...styles.card, ...(style || {}) }}>
      {children}
    </Grid>
  );
}

Card.propTypes = {
  children: PropTypes.oneOfType(
    [PropTypes.element, PropTypes.arrayOf(PropTypes.element)],
  ).isRequired,
  style: PropTypes.shape({}),
};

Card.defaultProps = {
  style: {},
};

export default Card;
