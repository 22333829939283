export default [
  {
    name: 'Categoria 1',
    type: 'category',
    times: 259,
  },
  {
    name: 'Categoria 2',
    type: 'category',
    times: 234,
  },
  {
    name: 'Categoria 3',
    type: 'category',
    times: 226,
  },
  {
    name: 'Categoria 4',
    type: 'category',
    times: 223,
  },
  {
    name: 'Cidade 1 / AA',
    type: 'city',
    times: 198,
  },
  {
    name: 'Categoria 5',
    type: 'category',
    times: 172,
  },
  {
    name: 'Cidade 2 / AA',
    type: 'city',
    times: 153,
  },
  {
    name: 'Cidade 3 / AA',
    type: 'city',
    times: 120,
  },
  {
    name: 'Categoria 6',
    type: 'category',
    times: 103,
  },
  {
    name: 'Categoria 7',
    type: 'category',
    times: 95,
  },
];
