export default [
  {
    name: 'Leilão 1',
    category: 'Categoria',
    companySite: 'https://google.com',
    companyName: 'Google',
    appView: 259,
    siteView: 158,
    conversion: Math.round((158 / 259) * 100),
  },
  {
    name: 'Leilão 2',
    category: 'Categoria',
    companySite: 'https://google.com',
    companyName: 'Google',
    appView: 234,
    siteView: 91,
    conversion: Math.round((91 / 234) * 100),
  },
  {
    name: 'Leilão 3',
    category: 'Categoria',
    companySite: 'https://google.com',
    companyName: 'Google',
    appView: 226,
    siteView: 108,
    conversion: Math.round((108 / 226) * 100),
  },
  {
    name: 'Leilão 4',
    category: 'Categoria',
    companySite: 'https://google.com',
    companyName: 'Google',
    appView: 223,
    siteView: 85,
    conversion: Math.round((85 / 223) * 100),
  },
  {
    name: 'Leilão 5',
    category: 'Categoria',
    companySite: 'https://google.com',
    companyName: 'Google',
    appView: 198,
    siteView: 62,
    conversion: Math.round((62 / 198) * 100),
  },
  {
    name: 'Leilão 6',
    category: 'Categoria',
    companySite: 'https://google.com',
    companyName: 'Google',
    appView: 172,
    siteView: 78,
    conversion: Math.round((78 / 172) * 100),
  },
  {
    name: 'Leilão 7',
    category: 'Categoria',
    companySite: 'https://google.com',
    companyName: 'Google',
    appView: 153,
    siteView: 56,
    conversion: Math.round((56 / 153) * 100),
  },
  {
    name: 'Leilão 8',
    category: 'Categoria',
    companySite: 'https://google.com',
    companyName: 'Google',
    appView: 120,
    siteView: 42,
    conversion: Math.round((42 / 120) * 100),
  },
  {
    name: 'Leilão 9',
    category: 'Categoria',
    companySite: 'https://google.com',
    companyName: 'Google',
    appView: 103,
    siteView: 38,
    conversion: Math.round((38 / 103) * 100),
  },
  {
    name: 'Leilão 10',
    category: 'Categoria',
    companySite: 'https://google.com',
    companyName: 'Google',
    appView: 95,
    siteView: 24,
    conversion: Math.round((24 / 95) * 100),
  },
];
