import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { Grid, Typography } from '@mui/material';

import { format } from 'date-fns';
import chartsData from './fake-data/charts';

import style from './style';

function UserChart() {
  const [days, setDays] = useState([]);
  const [active, setActive] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [newUsers, setNewUsers] = useState([]);

  useEffect(() => {
    setDays(chartsData.map((day) => format(new Date(day.date), 'dd MMM')));
    setActive(chartsData.map((day) => day.data.users.active));
    setSubscriptions(chartsData.map((day) => day.data.users.subscriptions));
    setNewUsers(chartsData.map((day) => day.data.users.new));
  }, []);

  const option = {
    tooltip: {
      trigger: 'axis',
    },
    grid: {
      top: '3%',
      left: '0%',
      right: '4%',
      bottom: '0%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: days,
        axisLine: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        show: true,
        type: 'value',
        min: 0,
        axisLabel: {
          show: true,
        },
        splitLine: {
          lineStyle: {
            opacity: 0.05,
          },
        },
      },
    ],
    series: [
      {
        name: 'Usuários ativos',
        type: 'line',
        smooth: true,
        lineStyle: {
          width: 3,
        },
        emphasis: {
          focus: 'series',
        },
        data: active,
      },
      {
        name: 'Novos assinantes',
        type: 'line',
        smooth: true,
        lineStyle: {
          width: 3,
        },
        emphasis: {
          focus: 'series',
        },
        data: subscriptions,
      },
      {
        name: 'Novos usuários',
        type: 'line',
        smooth: true,
        lineStyle: {
          width: 3,
        },
        emphasis: {
          focus: 'series',
        },
        data: newUsers,
      },
    ],
  };

  return (
    <Grid item sx={style.card}>
      <Grid container direction="column">
        <Typography sx={style.title}>USUÁRIOS DO APP</Typography>
        <ReactECharts option={option} notMerge lazyUpdate />
        <Grid container justifyContent="center" alignItems="center" sx={{ mt: 4 }}>
          <Grid item>
            <Grid container wrap="nowrap" alignItems="center">
              <div style={style.subtitleColor('#ffdc60')} />
              <Typography sx={style.subtitleText}>Usuários ativos</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container wrap="nowrap" alignItems="center">
              <div style={style.subtitleColor('#91cc76')} />
              <Typography sx={style.subtitleText}>Novos assinantes</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container wrap="nowrap" alignItems="center">
              <div style={style.subtitleColor('#5570c6')} />
              <Typography sx={style.subtitleText}>Novos usuários</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UserChart;
