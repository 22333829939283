import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useParams, useNavigate } from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Loading from '@components/loading';

import AuthController from '@controllers/auth';

import { Eye, EyeOff } from 'react-feather';

import LogoExt from '@assets/logo-ext.png';

import styles from './style';

function AdminCreatePassword() {
  const navigate = useNavigate();
  const { token } = useParams();

  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ });

  const [user, setUser] = useState(null);

  useEffect(() => {
    const getUser = async () => {
      const data = await AuthController().getUserByCreatePassword(token);

      if (!data) navigate('/');
      setUser(data);
    };

    if (!user) getUser();
  }, [user, token, navigate]);

  useEffect(() => {
    if (password) setErrors((e) => ({ ...e, password: false }));
  }, [password]);

  useEffect(() => {
    if (rePassword) setErrors((e) => ({ ...e, rePassword: false }));
  }, [rePassword]);

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const errs = {};

    if (!password) errs.password = 'Digite sua senha';

    if (!rePassword) errs.rePassword = 'Repita sua senha';
    else if (rePassword !== password) errs.rePassword = 'As senhas não conferem';

    setErrors(errs);
    if (Object.keys(errs).length > 0) {
      setLoading(false);
      return;
    }

    await axios.post(
      'https://us-central1-clube-do-leilao.cloudfunctions.net/user-change-password',
      { userId: user.id, password },
    );

    navigate('/');

    setLoading(false);
  };

  if (!user) return <Loading />;

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={styles.container}
    >
      <Grid container justifyContent="center" style={{ marginBottom: 64 }}>
        <img src={LogoExt} alt="Logo do Clube do Leilão" />
      </Grid>
      <form onSubmit={submit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography color="secondary" fontSize={24} fontWeight={800}>
              Olá,
              {' '}
              {user?.name}
            </Typography>
            <Typography color="textSecondary" fontSize={18} sx={{ mb: 2, mt: 1 }}>
              Esse é seu primeiro acesso então, antes de mais nada, precisamos
              que crie uma senha para sua conta
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              color="secondary"
              error={errors.password}
              type={showPassword ? 'text' : 'password'}
              label="Crie uma senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <EyeOff /> : <Eye />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Grid item container justifyContent="space-between" alignItems="center" sx={styles.errorMessage}>
              {errors.password && (
                <FormHelperText error>{errors.password}</FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              color="secondary"
              error={errors.rePassword}
              type="password"
              label="Repita sua senha"
              value={rePassword}
              onChange={(e) => setRePassword(e.target.value)}
            />
            <Grid item container justifyContent="space-between" alignItems="center" sx={styles.errorMessage}>
              {errors.rePassword && (
                <FormHelperText error>{errors.rePassword}</FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid item container justifyContent="center">
            <LoadingButton loading={loading} variant="contained" type="submit" sx={styles.submit}>
              <Grid container alignItems="center">
                CRIAR SENHA
              </Grid>
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

export default AdminCreatePassword;
