import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { db } from '@services/firebase';
import { onSnapshot, collection } from 'firebase/firestore';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import Card from '@components/card';
import Loading from '@components/loading';
import DeleteDialog from '@components/dialog-delete';

import { useAuthContext } from '@contexts/auth';

import { Plus, Trash2, Edit2 } from 'react-feather';

// import styles from './style';

import FormUser from './form';

function Companies() {
  const navigate = useNavigate();

  const [authState] = useAuthContext();

  const [users, setUsers] = useState(null);

  const [openDelete, setOpenDelete] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [canEdit, setCanEdit] = useState(null);

  useEffect(() => {
    const unsub = onSnapshot(collection(db, 'users'), (snap) => {
      const docs = snap.docs.map((d) => ({ ...d.data(), id: d.id }));
      setUsers(docs.filter((d) => !d.companyId));
    });

    return unsub;
  }, []);

  useEffect(() => {
    if (authState.user?.companyId) navigate('/');
    else if (!authState.user?.owner && !authState.user?.permissions?.admins?.read) navigate('/');
    else setCanEdit(authState.user?.owner || authState.user?.permissions?.admins?.edit);
  }, [authState]);

  return (
    <Grid
      container
      wrap="nowrap"
      justifyContent="center"
      alignItems="center"
    >
      <Card>
        <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" sx={{ marginBottom: 3 }}>
          <Typography color="primary" variant="h4">
            ADMINISTRADORES
          </Typography>
          {canEdit && (
            <Button size="large" variant="contained" color="primary" onClick={() => setOpenDrawer(true)}>
              <Plus style={{ marginRight: 8 }} />
              Adicionar admin
            </Button>
          )}
        </Grid>
        <TableContainer>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 800, color: 'secondary.main' }}>
                  Nome
                </TableCell>
                <TableCell sx={{ fontWeight: 800, color: 'secondary.main' }}>
                  E-mail
                </TableCell>
                <TableCell style={{ width: 54 }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {!users && (
                <TableRow>
                  <TableCell colSpan={7}>
                    <Loading height={300} />
                  </TableCell>
                </TableRow>
              )}
              {users?.map((row) => (
                <TableRow>
                  <TableCell>
                    {row.name}
                  </TableCell>
                  <TableCell>
                    {row.email}
                  </TableCell>
                  <TableCell>
                    {canEdit && authState.user.uid !== row.id && !row.owner && (
                      <Grid container wrap="nowrap" justifyContent="flex-end">
                        <IconButton
                          sx={{ marginRight: 1 }}
                          onClick={() => {
                            setSelectedUser(row);
                            setOpenDrawer(true);
                          }}
                        >
                          <Edit2 size={18} />
                        </IconButton>
                        <IconButton onClick={() => setOpenDelete(row)}>
                          <Trash2 size={18} />
                        </IconButton>
                      </Grid>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <FormUser
        open={openDrawer}
        user={selectedUser}
        onClose={() => {
          setSelectedUser(null);
          setOpenDrawer(false);
        }}
      />

      <DeleteDialog
        open={!!openDelete}
        title={`Tem certeza que deseja remover ${openDelete.name} dos administradores?`}
        onDelete={async () => {
          await axios.post(
            'https://us-central1-clube-do-leilao.cloudfunctions.net/user-delete',
            openDelete,
          );
          setOpenDelete(false);
        }}
        onClose={() => setOpenDelete(false)}
      />
    </Grid>
  );
}

export default Companies;
