import React, { useState } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import axios from 'axios';
import { IMaskInput } from 'react-imask';

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Eye, EyeOff, X } from 'react-feather';
import { InputAdornment } from '@mui/material';

const PhoneMask = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  const length = other.value?.length;

  return (
    <IMaskInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      mask={length > 14 ? '(#0) 00000-0000' : '(#0) 0000-00000'}
      definitions={{
        '#': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

PhoneMask.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function FormUser({ open, onClose }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ name: false, email: false });

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    if (!loading) {
      setName('');
      setEmail('');
      setPhone('');
      setPassword('');
      setShowPassword(false);
      setErrors({ name: false, email: false, phone: false });
      onClose();
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const errs = { name: false, email: false, phone: false };

    if (!name) errs.name = 'Informe o nome do usuário';

    if (!email) errs.email = 'Informe o e-mail do usuário';
    else if (!validator.isEmail(email)) {
      errs.email = 'Precisa ser um e-mail válido';
    }

    if (!phone) errs.phone = 'Informe o número do usuário';
    else if (phone.length < 14) errs.phone = 'Informe um número válido';

    setErrors(errs);

    if (errs.name || errs.email || errs.phone) {
      setLoading(false);
      return;
    }

    const data = {
      name, email, password, phone,
    };

    try {
      const { data: response } = await axios.post('https://us-central1-clube-do-leilao.cloudfunctions.net/user-app-store', data);

      setLoading(false);
      if (response.error) {
        setErrors({ ...errs, email: 'Este e-mail já está cadastrado' });
      } else handleClose();
    } catch (error) {
      setErrors({ ...errs, all: error.message });
    }
  };

  return (
    <Drawer
      anchor="right"
      open={!!open}
      onClose={handleClose}
      PaperProps={{ sx: { backgroundImage: 'none' } }}
    >
      <Grid container direction="column" sx={{ width: '500px', padding: 4 }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Typography fontWeight={800} fontSize={20}>
            Adicionar usuário do app
          </Typography>
          <IconButton onClick={handleClose}>
            <X />
          </IconButton>
        </Grid>
        <form onSubmit={submit}>
          <Grid container direction="column" spacing={4} sx={{ flexGrow: 1, padding: '32px 0' }}>
            <Grid item>
              <TextField
                error={errors.name}
                fullWidth
                label="Nome completo"
                value={name}
                name="name"
                onChange={(e) => setName(e.target.value)}
                color="secondary"
              />
              {errors.name && (
                <FormHelperText error>{errors.name}</FormHelperText>
              )}
            </Grid>
            <Grid item>
              <Grid container alignItems="center" wrap="nowrap">
                <Grid container>
                  <TextField
                    error={errors.email}
                    fullWidth
                    label="E-mail"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    color="secondary"
                  />
                  {errors.email && (
                    <FormHelperText error>{errors.email}</FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" wrap="nowrap">
                <Grid container>
                  <TextField
                    error={errors.phone}
                    fullWidth
                    label="Celular"
                    type="tel"
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    color="secondary"
                    InputProps={{ inputComponent: PhoneMask }}
                  />
                  {errors.phone && (
                    <FormHelperText error>{errors.phone}</FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" wrap="nowrap">
                <Grid container>
                  <TextField
                    error={errors.password}
                    fullWidth
                    label="Senha"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    color="secondary"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <EyeOff /> : <Eye />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors.password && (
                    <FormHelperText error>{errors.password}</FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                disabled={loading}
                size="large"
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                type="submit"
                loading={loading}
                size="large"
                variant="contained"
                color="primary"
              >
                Adicionar
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Drawer>
  );
}

FormUser.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    permissions: PropTypes.shape({}),
  }),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

FormUser.defaultProps = {
  user: null,
};

export default FormUser;
