import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSWRConfig } from 'swr';
import axios from 'axios';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import DeleteDialog from '@components/dialog-delete';
import ConfirmDialog from '@components/dialog-confirm';

import { RotateCcw, Trash2, Unlock } from 'react-feather';

function Row({ user, canEdit }) {
  const { mutate } = useSWRConfig();

  const [openDelete, setOpenDelete] = useState(false);
  const [openUnLock, setOpenUnLock] = useState(false);

  const undoDelete = async (idUser) => {
    await axios.post(
      'https://us-central1-clube-do-leilao.cloudfunctions.net/user-app-undo-delete',
      { idUser },
    );
    mutate('https://us-central1-clube-do-leilao.cloudfunctions.net/user-app-index');
  };

  const userStatus = {
    text: 'Liberado',
    color: 'success.main',
  };
  let cellStyle = {};
  if (user.deletedAt) {
    userStatus.text = 'Desativado';
    userStatus.color = 'error.main';
    cellStyle = { color: 'text.secondary', fontStyle: 'italic' };
  } else if (user.trialEndAt) {
    userStatus.text = 'Em trial';
    userStatus.color = 'warning.main';
  }
  return (
    <>
      <TableRow>
        <TableCell sx={cellStyle}>
          {user.name}
        </TableCell>
        <TableCell sx={cellStyle}>
          {user.email}
        </TableCell>
        <TableCell sx={cellStyle}>
          {user.phone}
        </TableCell>
        <TableCell>
          <Typography sx={{ color: userStatus.color }}>
            {userStatus.text}
          </Typography>
        </TableCell>
        <TableCell>
          {canEdit && (
          <Grid container wrap="nowrap" justifyContent="flex-end">
            {user.trialEndAt && (
              <Tooltip title="Liberar acesso" placement="left">
                <IconButton sx={{ marginRight: 1 }} onClick={() => setOpenUnLock(user)}>
                  <Unlock size={18} />
                </IconButton>
              </Tooltip>
            )}
            {user.deletedAt ? (
              <Tooltip title="Restaurar conta" placement="left">
                <IconButton onClick={() => undoDelete(user.idUser)}>
                  <RotateCcw size={18} />
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton onClick={() => setOpenDelete(user)}>
                <Trash2 size={18} />
              </IconButton>
            )}
          </Grid>
          )}
        </TableCell>
      </TableRow>

      <DeleteDialog
        open={!!openDelete}
        title={`Tem certeza que deseja excluir ${openDelete.name} do aplicativo?`}
        onDelete={async () => {
          await axios.post(
            'https://us-central1-clube-do-leilao.cloudfunctions.net/user-app-delete',
            { idUser: openDelete.idUser },
          );
          mutate('https://us-central1-clube-do-leilao.cloudfunctions.net/user-app-index');
          setOpenDelete(false);
        }}
        onClose={() => setOpenDelete(false)}
      />

      <ConfirmDialog
        open={!!openUnLock}
        title={`Tem certeza que deseja liberar o acesso completo do aplicativo para ${openUnLock.name}?`}
        onConfirm={async () => {
          await axios.post(
            'https://us-central1-clube-do-leilao.cloudfunctions.net/user-app-unlock',
            { idUser: openUnLock.idUser },
          );
          mutate('https://us-central1-clube-do-leilao.cloudfunctions.net/user-app-index');
        }}
        onClose={() => setOpenUnLock(false)}
      />
    </>
  );
}

Row.propTypes = {
  user: PropTypes.shape({
    idUser: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    trialEndAt: PropTypes.number,
    deletedAt: PropTypes.number,
  }).isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default Row;
