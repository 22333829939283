import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function DeleteDialog({
  open, title, description, onConfirm, onClose,
}) {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    await onConfirm();
    setLoading(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={() => !loading && onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ paddingTop: 3 }}>{title}</DialogTitle>
      {description && (
        <DialogContent sx={{ marginTop: 1 }}>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions sx={{ padding: 3 }}>
        <Button disabled={loading} variant="outlined" color="secondary" onClick={onClose}>
          CANCELAR
        </Button>
        <LoadingButton loading={loading} variant="contained" color="primary" onClick={handleConfirm}>
          CONFIRMAR
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

DeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

DeleteDialog.defaultProps = {
  description: '',
};

export default DeleteDialog;
