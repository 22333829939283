import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
// import axios from 'axios';

import { auth, storage, db } from '@services/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import {
  updateProfile, updateEmail, reauthenticateWithCredential, EmailAuthProvider,
} from 'firebase/auth';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useAuthContext } from '@contexts/auth';

import {
  X, Upload, Eye, EyeOff,
} from 'react-feather';

import styles from './style';

const getInitials = (name) => {
  if (name) {
    const splitted = name.split(' ');
    if (splitted.length > 1) {
      return (splitted[0][0] + splitted.reverse()[0][0]).toUpperCase();
    }

    return (splitted[0][0] + splitted[0][1]).toUpperCase();
  }
  return null;
};

function FormUser({ open, onClose }) {
  const [authState, setAuthState] = useAuthContext();

  const [avatar, setAvatar] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ password: false, name: false, email: false });

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (authState.user) {
      setName(authState.user.displayName || '');
      setEmail(authState.user.email);
      setAvatar(authState.user.photoURL);
    }
  }, [authState.user, open]);

  const handleClose = () => {
    if (!loading) {
      setAvatar('');
      setName('');
      setEmail('');
      setPassword('');
      setShowPassword(false);
      setErrors({ password: false, name: false, email: false });
      onClose();
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const errs = { password: false, name: false, email: false };

    if (!name) errs.name = 'Você precisa ter um nome';

    if (!email) errs.email = 'Você precisa ter um e-mail de acesso';
    else if (!validator.isEmail(email)) {
      errs.email = 'Precisa ser um e-mail válido';
    }

    if (!password) errs.password = 'Você precisa inserir sua senha atual';

    setErrors(errs);

    if (errs.name || errs.email || errs.password) {
      setLoading(false);
      return;
    }

    let avatarUrl = avatar;
    if (avatar && typeof avatar === 'object') {
      const iconRef = ref(storage, `users/${authState.user.uid}.png`);
      await uploadBytes(iconRef, avatar);
      avatarUrl = await getDownloadURL(iconRef);
    }

    try {
      const credential = EmailAuthProvider.credential(authState.user.email, password);
      await reauthenticateWithCredential(auth.currentUser, credential);

      if (name !== authState.user.displayName || avatar !== authState.user.photoURL) {
        await updateProfile(auth.currentUser, { displayName: name, photoURL: avatarUrl });
      }
      if (email !== authState.user.email) {
        await updateEmail(auth.currentUser, email);
      }

      const userRef = doc(db, 'users', authState.user.uid);
      const data = { name, email, avatar: avatarUrl || null };
      await updateDoc(userRef, data);

      setAuthState((a) => ({ ...a, user: { ...authState.user, ...auth.currentUser } }));

      setLoading(false);
      handleClose();
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        setLoading(false);
        setErrors({ ...errs, password: 'Esta senha está incorreta' });
      }

      if (error.code === 'auth/email-already-in-use') {
        setLoading(false);
        setErrors({ ...errs, email: 'Este e-mail já está sendo usado' });
      }
    }
  };

  return (
    <Drawer
      anchor="right"
      open={!!open}
      onClose={handleClose}
      PaperProps={{ sx: { backgroundImage: 'none' } }}
    >
      <Grid container direction="column" sx={{ width: '500px', padding: 4 }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Typography fontWeight={800} fontSize={20}>
            Editar perfil
          </Typography>
          <IconButton onClick={handleClose}>
            <X />
          </IconButton>
        </Grid>
        <form onSubmit={submit}>
          <Grid container direction="column" spacing={4} sx={{ flexGrow: 1, padding: '32px 0' }}>
            <Grid item>
              <Grid container alignItems="center" wrap="nowrap">
                <Avatar
                  alt={authState.user.displayName}
                  src={avatar && typeof avatar === 'object' ? URL.createObjectURL(avatar) : avatar}
                  sx={styles.formAvatar}
                >
                  {getInitials(authState.user?.displayName)}
                </Avatar>
                <Grid container>
                  <Button
                    fullWidth
                    color="secondary"
                    size="large"
                    variant="contained"
                    component="label"
                  >
                    <Upload size={18} style={{ marginRight: 16 }} />
                    {avatar ? 'Alterar' : 'Adicionar'}
                    {' '}
                    foto de perfil
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={(e) => {
                        if (e.target.files[0]) {
                          setAvatar(e.target.files[0]);
                        }
                      }}
                    />
                  </Button>
                </Grid>
                {avatar && (
                  <Tooltip placement="bottom-end" title="Remover foto de perfil">
                    <Button
                      color="secondary"
                      variant="contained"
                      sx={{ padding: '9px 13px', minWidth: 0, marginLeft: 2 }}
                      onClick={() => setAvatar('')}
                    >
                      <X />
                    </Button>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <TextField
                error={!!errors.name}
                fullWidth
                label="Nome completo"
                value={name}
                name="name"
                onChange={(e) => setName(e.target.value)}
                color="secondary"
              />
              {errors.name && (
                <FormHelperText error>{errors.name}</FormHelperText>
              )}
            </Grid>
            <Grid item>
              <Grid container alignItems="center" wrap="nowrap">
                <Grid container>
                  <TextField
                    error={!!errors.email}
                    fullWidth
                    label="E-mail"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    color="secondary"
                  />
                  {errors.email && (
                    <FormHelperText error>{errors.email}</FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <FormHelperText sx={{ marginBottom: 2 }}>
                Precisamos da sua senha atual para confirmar as alterações no seu perfil
              </FormHelperText>
              <TextField
                error={!!errors.password}
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label="Senha atual"
                value={password}
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                color="secondary"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <EyeOff /> : <Eye />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.password && (
                <FormHelperText error>{errors.password}</FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                disabled={loading}
                size="large"
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                type="submit"
                loading={loading}
                size="large"
                variant="contained"
                color="primary"
              >
                Salvar
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Drawer>
  );
}

FormUser.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default FormUser;
