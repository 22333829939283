import React from 'react';
import {
  Chip, Grid, Typography,
} from '@mui/material';

import notifications from './fake-data/top-notifications';

import style from './style';

const typeName = {
  category: 'Categoria',
  city: 'Cidade',
};

function TopAuctions() {
  return (
    <Grid item sx={style.card}>
      <Grid container direction="column">
        <Grid container wrap="nowrap" alignItems="center" justifyContent="space-between">
          <Typography sx={(theme) => ({ ...style.title(theme), mb: 0 })}>NOTIFICAÇÕES</Typography>
          <Grid container direction="column" alignItems="flex-end" justifyContent="center">
            <Typography sx={{
              color: 'text.secondary', fontSize: 14, mb: 1, lineHeight: 1, mr: 0.5,
            }}
            >
              Total
            </Typography>
            <Typography sx={{
              fontWeight: 600, color: 'primary.main', fontSize: 36, mb: 0, lineHeight: 1,
            }}
            >
              2579
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ mt: 4 }}>
          {notifications.map((notification) => (
            <Grid container wrap="nowrap" alignItems="center" sx={style.cardList}>
              <Grid container wrap="nowrap" alignItems="center">
                <Typography sx={{ width: 1 }}>
                  {notification.name}
                </Typography>
                <Chip color="primary" label={typeName[notification.type]} size="small" sx={{ mr: 1 }} />
              </Grid>
              <Grid container justifyContent="center" sx={{ minWidth: 50, width: 50, m: '0 4px' }}>{notification.times}</Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TopAuctions;
