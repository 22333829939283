import React, { useState, useEffect } from 'react';
import { IMaskInput } from 'react-imask';
import PropTypes from 'prop-types';
import validator from 'validator';
import axios from 'axios';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { X } from 'react-feather';

const existName = (id, name, companies) => companies
  .some((c) => c.idCompany !== id && c.name.toLowerCase() === name.toLowerCase());
const existSite = (id, site, companies) => companies
  .some((c) => c.idCompany !== id && c.site.toLowerCase() === site.toLowerCase());

const formatUrl = (url) => `https://${url}`.replace('https://http', 'http');

const options = [
  { label: 'Brasil', value: 'BR' },
  { label: 'Acre', value: 'AC' },
  { label: 'Alagoas', value: 'AL' },
  { label: 'Amapá', value: 'AP' },
  { label: 'Amazonas', value: 'AM' },
  { label: 'Bahia', value: 'BA' },
  { label: 'Ceará', value: 'CE' },
  { label: 'Destrito Federal', value: 'DF' },
  { label: 'Espírito Santo', value: 'ES' },
  { label: 'Goiás', value: 'GO' },
  { label: 'Maranhão', value: 'MA' },
  { label: 'Mato Grosso', value: 'MT' },
  { label: 'Mato Grosso do Sul', value: 'MS' },
  { label: 'Minas Gerais', value: 'MG' },
  { label: 'Pará', value: 'PA' },
  { label: 'Paraíba', value: 'PB' },
  { label: 'Paraná', value: 'PR' },
  { label: 'Pernambuco', value: 'PE' },
  { label: 'Piauí', value: 'PI' },
  { label: 'Rio de Janeiro', value: 'RJ' },
  { label: 'Rio Grande do Norte', value: 'RN' },
  { label: 'Rio Grande do Sul', value: 'RS' },
  { label: 'Rondônia', value: 'RO' },
  { label: 'Roraima', value: 'RR' },
  { label: 'Santa Catarina', value: 'SC' },
  { label: 'São Paulo', value: 'SP' },
  { label: 'Sergipe', value: 'SE' },
  { label: 'Tocantins', value: 'TO' },
];

const TextMaskCustom = React.forwardRef((props, ref) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
      mask="(#0) 00000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

TextMaskCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function FormCompany({
  open, company, companies, onClose,
}) {
  const [name, setName] = useState('');
  const [site, setSite] = useState('');
  const [resName, setResName] = useState('');
  const [resEmail, setResEmail] = useState('');
  const [resPhone, setResPhone] = useState('');
  const [states, setStates] = useState([]);
  const [errors, setErrors] = useState({ name: false, site: false, states: false });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (company) {
      setName(company.name);
      setSite(company.site);
      setStates(company.states);
      setResName(company.resName);
      setResEmail(company.resEmail);
      setResPhone(company.resPhone);
    }
  }, [company]);

  const handleClose = () => {
    if (!loading) {
      setName('');
      setSite('');
      setStates([]);
      setResName('');
      setResEmail('');
      setResPhone('');
      setErrors({ name: false, site: false, states: false });
      onClose();
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const errs = { name: false, site: false, states: false };

    if (!name) errs.name = 'O leiloeiro precisa ter um nome';
    else if (existName(company?.idCompany, name, companies)) {
      errs.name = 'Já existe um leiloeiro com este nome';
    }
    if (!site) errs.site = 'Insira o site';
    else if (!validator.isURL(formatUrl(site))) {
      errs.site = 'Precisa ser um site válido. Ex: www.clubedoleilao.com';
    } else if (existSite(company?.idCompany, site, companies)) {
      errs.site = 'Já existe um leiloeiro com este site';
    }
    if (!states || states.length === 0) {
      errs.states = 'Selecione ao menos uma área de atuação';
    }

    setErrors(errs);

    if (errs.name || errs.site) {
      setLoading(false);
      return;
    }

    const data = {
      name, site: formatUrl(site), states, resName, resEmail, resPhone,
    };

    let postUrl = 'https://us-central1-clube-do-leilao.cloudfunctions.net/company-store';
    if (company) {
      data.idCompany = company.idCompany;
      postUrl = 'https://us-central1-clube-do-leilao.cloudfunctions.net/company-update';
    }

    await axios.post(postUrl, data);
    setLoading(false);
    handleClose();
  };

  return (
    <Drawer
      anchor="right"
      open={!!open}
      onClose={handleClose}
      PaperProps={{ sx: { backgroundImage: 'none' } }}
    >
      <Grid container direction="column" sx={{ width: '500px', padding: 4 }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Typography fontWeight={800} fontSize={20}>
            {company ? `Editar ${company.name}` : 'Adicionar leiloeiro' }
          </Typography>
          <IconButton onClick={handleClose}>
            <X />
          </IconButton>
        </Grid>
        <form onSubmit={submit}>
          <Grid container direction="column" spacing={4} sx={{ flexGrow: 1, padding: '32px 0' }}>
            <Grid item>
              <TextField
                error={errors.name}
                fullWidth
                label="Nome do leiloeiro"
                value={name}
                onChange={(e) => setName(e.target.value)}
                color="secondary"
              />
              {errors.name && (
                <FormHelperText error>{errors.name}</FormHelperText>
              )}
            </Grid>
            <Grid item>
              <Grid container alignItems="center" wrap="nowrap">
                {site && (
                  <img
                    src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${formatUrl(site)}`}
                    alt={name}
                    style={{ width: 18, height: 18, margin: '0 32px 0 16px' }}
                  />
                )}
                <Grid container>
                  <TextField
                    error={errors.site}
                    fullWidth
                    label="Site"
                    value={site}
                    onChange={(e) => setSite(e.target.value)}
                    color="secondary"
                  />
                  {errors.site && (
                    <FormHelperText error>{errors.site}</FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <FormControl sx={{ width: '100%' }}>
                <InputLabel id="demo-multiple-checkbox-label">Atuação</InputLabel>
                <Select
                  error={errors.states}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  fullWidth
                  value={states}
                  onChange={(e) => setStates(e.target.value)}
                  input={<OutlinedInput label="Atuação" />}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={{
                    PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } },
                  }}
                >
                  {options.map((state) => (
                    <MenuItem key={state.value} value={state.value}>
                      <Checkbox checked={states?.indexOf(state.value) > -1} />
                      <ListItemText primary={state.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errors.states && (
                <FormHelperText error>{errors.states}</FormHelperText>
              )}
            </Grid>
            <Grid item>
              <Typography sx={{ fontWeight: 800, fontSize: 14 }}>
                INFORMAÇÕES DE CONTATO
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Nome do contato"
                value={resName}
                onChange={(e) => setResName(e.target.value)}
                color="secondary"
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="E-mail"
                type="email"
                value={resEmail}
                onChange={(e) => setResEmail(e.target.value)}
                color="secondary"
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Whatsapp/Telefone"
                value={resPhone}
                onChange={(e) => setResPhone(e.target.value)}
                color="secondary"
                InputProps={{ inputComponent: TextMaskCustom }}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                disabled={loading}
                size="large"
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                type="submit"
                loading={loading}
                size="large"
                variant="contained"
                color="primary"
              >
                {company ? 'Salvar' : 'Adicionar'}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Drawer>
  );
}

FormCompany.propTypes = {
  company: PropTypes.shape({
    idCompany: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    site: PropTypes.string,
    states: PropTypes.arrayOf(PropTypes.string),
    resName: PropTypes.string,
    resEmail: PropTypes.string,
    resPhone: PropTypes.string,
  }),
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      idCompany: PropTypes.number.isRequired,
      name: PropTypes.string,
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

FormCompany.defaultProps = {
  company: null,
};

export default FormCompany;
