import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useSearchParams } from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Loading from '@components/loading';

import { Eye, EyeOff } from 'react-feather';

import LogoExt from '@assets/logo-ext.png';
import AppStore from '@assets/app-store.png';
import GooglePlay from '@assets/google-play.png';

import styles from './style';

function SignIn() {
  const [params] = useSearchParams();

  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ });

  const [user, setUser] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const [finish, setFinish] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      const userId = params.get('userId');
      const token = params.get('token');

      const { data } = await axios.get(
        `https://us-central1-clube-do-leilao.cloudfunctions.net/user_app_valid?userId=${userId}&token=${token}`,
      );

      if (!data) setNotFound(true);
      setUser(data);
    };

    if (!user) getUser();
  }, [user, params]);

  useEffect(() => {
    if (password) setErrors((e) => ({ ...e, password: false }));
  }, [password]);

  useEffect(() => {
    if (rePassword) setErrors((e) => ({ ...e, rePassword: false }));
  }, [rePassword]);

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const errs = {};

    if (!password) errs.password = 'Digite sua senha';

    if (!rePassword) errs.rePassword = 'Repita sua senha';
    else if (rePassword !== password) errs.rePassword = 'As senhas não conferem';

    setErrors(errs);
    if (Object.keys(errs).length > 0) {
      setLoading(false);
      return;
    }

    const userId = params.get('userId');
    await axios.post(
      'https://us-central1-clube-do-leilao.cloudfunctions.net/user_app_create_pass',
      { userId, password },
    );

    setFinish(true);

    setLoading(false);
  };

  if (notFound) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={styles.container}
      >
        <Grid container justifyContent="center" style={{ marginBottom: 64 }}>
          <img src={LogoExt} alt="Logo do Clube do Leilão" />
        </Grid>
        <Grid container direction="column" style={{ marginBottom: 64 }}>
          <Typography textAlign="center" color="secondary" fontSize={32} fontWeight={800}>
            Não encontramos nenhuma solicitação!
          </Typography>
          <Typography textAlign="center" color="textSecondary" sx={{ marginTop: 4 }}>
            Entre em contato com nosso suporte para mais informações.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  if (finish) {
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={styles.container}
      >
        <Grid container justifyContent="center" style={{ marginBottom: 64 }}>
          <img src={LogoExt} alt="Logo do Clube do Leilão" />
        </Grid>
        <Grid container direction="column" style={{ marginBottom: 64 }}>
          <Typography textAlign="center" color="secondary" fontSize={32} fontWeight={800}>
            Tudo certo para acessar o app do Clube do Leilão!
          </Typography>
          <Typography textAlign="center" color="textSecondary" sx={{ marginTop: 4 }}>
            Agora você pode baixar o applicativo nos links abaixo e entrar com seu e-mail e senha
            que acabou de criar.
          </Typography>
          <Grid container wrap="nowrap" justifyContent="center" sx={{ marginTop: 6 }}>
            <a href="https://apps.apple.com/us/app/clube-do-leil%C3%A3o/id1659827874">
              <img src={AppStore} style={{ height: 56, marginRight: 16 }} alt="Baixar na AppStore" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.gusolden.clubedoleilao">
              <img src={GooglePlay} style={{ height: 56 }} alt="Baixar no Google Play" />
            </a>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (!user) return <Loading />;

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={styles.container}
    >
      <Grid container justifyContent="center" style={{ marginBottom: 64 }}>
        <img src={LogoExt} alt="Logo do Clube do Leilão" />
      </Grid>
      <form onSubmit={submit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography color="secondary" fontSize={24} fontWeight={800}>
              Olá,
              {' '}
              {user?.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              color="secondary"
              error={errors.password}
              type={showPassword ? 'text' : 'password'}
              label="Crie uma senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <EyeOff /> : <Eye />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Grid item container justifyContent="space-between" alignItems="center" sx={styles.errorMessage}>
              {errors.password && (
                <FormHelperText error>{errors.password}</FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              color="secondary"
              error={errors.rePassword}
              type="password"
              label="Repita sua senha"
              value={rePassword}
              onChange={(e) => setRePassword(e.target.value)}
            />
            <Grid item container justifyContent="space-between" alignItems="center" sx={styles.errorMessage}>
              {errors.rePassword && (
                <FormHelperText error>{errors.rePassword}</FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid item container justifyContent="center">
            <LoadingButton loading={loading} variant="contained" type="submit" sx={styles.submit}>
              <Grid container alignItems="center">
                CRIAR SENHA
              </Grid>
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

export default SignIn;
