import React, {
  createContext, useContext, useMemo, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { auth, db } from '@services/firebase';
import { onSnapshot, doc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

const initState = {
  loading: true,
  user: null,
  signError: null,
};

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [authState, setAuthState] = useState(initState);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        onSnapshot(doc(db, 'users', user.uid), (docSnap) => {
          const data = docSnap.data();
          setAuthState({ loading: false, user: { ...user, ...data }, signError: null });
        });
      } else {
        setAuthState({ loading: false, user: null, signError: null });
      }
    });
  }, []);

  const contextValue = useMemo(() => [authState, setAuthState], [authState, setAuthState]);

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export const useAuthContext = () => useContext(AuthContext);
