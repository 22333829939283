import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import axios from 'axios';

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { X } from 'react-feather';

function FormUser({
  open, companyId, companies, onClose,
}) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ name: false, email: false });

  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (companyId) {
      setCompany(companies.find((c) => c.idCompany === companyId));
    }
  }, [companyId]);

  const handleClose = () => {
    if (!loading) {
      setName('');
      setEmail('');
      setCompany(null);
      setErrors({ name: false, email: false });
      onClose();
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const errs = { name: false, email: false };

    if (!name) errs.name = 'O responsável precisa ter um nome';

    if (!email) errs.email = 'O responsável precisa ter um e-mail de acesso';
    else if (!validator.isEmail(email)) {
      errs.email = 'Precisa ser um e-mail válido';
    }

    setErrors(errs);

    if (errs.name || errs.email) {
      setLoading(false);
      return;
    }

    const data = { name, email, companyId };

    try {
      await axios.post('https://us-central1-clube-do-leilao.cloudfunctions.net/user-store', data);
      setLoading(false);
      handleClose();
    } catch (error) {
      if (error.response?.data === 'auth/email-already-exists') {
        setLoading(false);
        setErrors({ ...errs, email: 'Este e-mail já está cadastrado' });
      }
    }
  };

  return (
    <Drawer
      anchor="right"
      open={!!open}
      onClose={handleClose}
      PaperProps={{ sx: { backgroundImage: 'none' } }}
    >
      <Grid container direction="column" sx={{ width: '500px', padding: 4 }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Typography fontWeight={800} fontSize={20}>
            Adicionar responsável
          </Typography>
          <IconButton onClick={handleClose}>
            <X />
          </IconButton>
        </Grid>
        <form onSubmit={submit}>
          <Grid container direction="column" spacing={4} sx={{ flexGrow: 1, padding: '32px 0' }}>
            {company && (
              <Grid item>
                <TextField
                  fullWidth
                  select
                  label="Responsável por"
                  value={companyId}
                  disabled
                  color="secondary"
                >
                  <MenuItem value={companyId}>
                    <Grid container alignItems="center">
                      <img
                        src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${company.site}`}
                        alt={company.name}
                        style={{ marginRight: 16 }}
                      />
                      {company.name}
                    </Grid>
                  </MenuItem>
                </TextField>
              </Grid>
            )}
            <Grid item>
              <TextField
                error={errors.name}
                fullWidth
                label="Nome do leiloeiro"
                value={name}
                name="name"
                onChange={(e) => setName(e.target.value)}
                color="secondary"
              />
              {errors.name && (
                <FormHelperText error>{errors.name}</FormHelperText>
              )}
            </Grid>
            <Grid item>
              <Grid container alignItems="center" wrap="nowrap">
                <Grid container>
                  <TextField
                    error={errors.email}
                    fullWidth
                    label="E-mail"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    color="secondary"
                  />
                  {errors.email && (
                    <FormHelperText error>{errors.email}</FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                disabled={loading}
                size="large"
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                type="submit"
                loading={loading}
                size="large"
                variant="contained"
                color="primary"
              >
                Adicionar
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Drawer>
  );
}

FormUser.propTypes = {
  companyId: PropTypes.number,
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      idCompany: PropTypes.number.isRequired,
      name: PropTypes.string,
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

FormUser.defaultProps = {
  companyId: null,
};

export default FormUser;
