import React, { useState } from 'react';
import { useSWRConfig } from 'swr';
import PropTypes from 'prop-types';
import axios from 'axios';

import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';

import DeleteDialog from '@components/dialog-delete';

import {
  Plus, ChevronDown, ChevronUp, CornerDownRight, Edit2, Trash2, User,
} from 'react-feather';

import WppIcon from '@assets/whatsapp.png';

function Row({
  row, onAdd, onEdit, canEdit,
}) {
  const { mutate } = useSWRConfig();
  const [open, setOpen] = useState(false);

  const [openDeleteSite, setOpenDeleteSite] = useState(false);
  const [openDeleteUser, setOpenDeleteUser] = useState(false);

  const cleanPhone = (phone) => phone
    .replace('(', '')
    .replace(')', '')
    .replace(' ', '')
    .replace('-', '');

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <ChevronUp /> : <ChevronDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          <img
            src={`https://s2.googleusercontent.com/s2/favicons?domain_url=${row.site}`}
            alt={row.name}
            style={{ width: 18, height: 18 }}
          />
        </TableCell>
        <TableCell style={{ fontWeight: 800 }}>
          {row.name}
        </TableCell>
        <TableCell style={{ fontWeight: 800 }}>
          {row.site}
        </TableCell>
        <TableCell style={{ fontWeight: 800 }}>
          {row.states.join(',')}
        </TableCell>
        <TableCell align="center" style={{ fontWeight: 800 }}>
          {row.totalAuctions}
        </TableCell>
        {canEdit && (
          <TableCell>
            <Grid container wrap="nowrap" justifyContent="flex-end">
              <IconButton sx={{ marginRight: 1 }} onClick={() => onEdit(row)}>
                <Edit2 size={18} />
              </IconButton>
              <Tooltip
                placement="left"
                title={(row.users.length > 0 || row.totalAuctions > 0)
                  && 'Você não pode excluir um site que tenham responsáveis ou leilões vinculados'}
              >
                <span>
                  <IconButton
                    disabled={row.users.length > 0 || row.totalAuctions > 0}
                    onClick={() => setOpenDeleteSite(row)}
                  >
                    <Trash2 size={18} />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table aria-label="subcategories">
              <TableBody>
                {(row.resName || row.resEmail || row.resPhone) && (
                  <TableRow>
                    <TableCell style={{ width: 34 }} />
                    <TableCell style={{ width: 24 }}>
                      <User size={16} style={{ opacity: 0.5 }} />
                    </TableCell>
                    <TableCell>
                      {row.resName}
                    </TableCell>
                    <TableCell>
                      {row.resEmail && (
                        <Link
                          color="inherit"
                          target="_blank"
                          href={`mailto:${row.resEmail}`}
                          underline="hover"
                        >
                          {row.resEmail}
                        </Link>
                      )}
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      {row.resPhone && (
                        <>
                          <img
                            src={WppIcon}
                            alt="Whats Icon"
                            style={{ width: 16, height: 16, margin: '0 12px -2px' }}
                          />
                          <Link
                            color="inherit"
                            target="_blank"
                            href={`https://api.whatsapp.com/send?phone=55${cleanPhone(row.resPhone)}`}
                            underline="hover"
                          >
                            {row.resPhone}
                          </Link>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                )}
                {row.users?.map((user) => (
                  <TableRow key={user.idUser}>
                    <TableCell style={{ width: 34 }} />
                    <TableCell style={{ width: 24 }}>
                      <CornerDownRight size={16} style={{ opacity: 0.5 }} />
                    </TableCell>
                    <TableCell>
                      {user.name}
                    </TableCell>
                    <TableCell>
                      {user.email}
                    </TableCell>
                    {canEdit && (
                      <TableCell style={{ width: 108 }}>
                        <Grid container wrap="nowrap" justifyContent="flex-end">
                          <IconButton onClick={() => setOpenDeleteUser(user)}>
                            <Trash2 size={18} />
                          </IconButton>
                        </Grid>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                {canEdit && (
                  <TableRow>
                    <TableCell style={{ width: 34, border: 'none' }} />
                    <TableCell colSpan={3} style={{ border: 'none' }}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => onAdd(row.idCompany)}
                      >
                        <Plus size={20} style={{ marginRight: 8 }} />
                        Adicionar responsável
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>

      <DeleteDialog
        open={!!openDeleteSite}
        title={`Tem certeza que deseja deletar o site ${openDeleteSite.name}?`}
        onDelete={async () => {
          await axios.post(
            'https://us-central1-clube-do-leilao.cloudfunctions.net/company-delete',
            openDeleteSite,
          );
          setOpenDeleteSite(false);
          mutate('https://us-central1-clube-do-leilao.cloudfunctions.net/companies-index');
        }}
        onClose={() => setOpenDeleteSite(false)}
      />

      <DeleteDialog
        open={!!openDeleteUser}
        title={`Tem certeza que deseja deletar ${openDeleteUser.name}?`}
        onDelete={async () => {
          await axios.post(
            'https://us-central1-clube-do-leilao.cloudfunctions.net/user-delete',
            openDeleteUser,
          );
          setOpenDeleteUser(false);
          mutate('https://us-central1-clube-do-leilao.cloudfunctions.net/companies-index');
        }}
        onClose={() => setOpenDeleteUser(false)}
      />
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    idCompany: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    site: PropTypes.string,
    resName: PropTypes.string,
    resEmail: PropTypes.string,
    resPhone: PropTypes.string,
    states: PropTypes.arrayOf(PropTypes.string),
    totalAuctions: PropTypes.number,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        idUser: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default Row;
