export default {
  container: {
    maxWidth: 500,
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    margin: '0 auto',
  },

  errorContainer: {
    color: 'error.contrastText',
    backgroundColor: 'error.dark',
    padding: 2,
    marginBottom: 1,
    borderRadius: 1,
  },

  errorMessage: {
    paddingTop: 1,
  },

  forgetPassword: {
    color: 'secondary.main',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },

  submit: {
    padding: '16px 48px',
    marginTop: 2,
    fontSize: 16,
    '& > div': {
      fontWeight: 700,
    },
  },
};
