import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { X } from 'react-feather';

const existName = (id, name, categories) => categories
  .some((c) => c.idCategory !== id && c.name.toLowerCase() === name.toLowerCase());

function FormCategory({
  open, category, subCategoryOf, categories, onClose,
}) {
  const [name, setName] = useState('');
  const [errors, setErrors] = useState({ name: false, icon: false });

  const [loading, setLoading] = useState(false);
  const [sub, setSub] = useState(null);

  useEffect(() => {
    if (category) {
      setName(category.name);
      if (category.subCategoryOf) {
        const cat = categories.find((c) => c.idCategory === category.subCategoryOf);
        if (cat) setSub(cat);
      }
    }
  }, [category, categories]);

  useEffect(() => {
    if (subCategoryOf) {
      const cat = categories.find((c) => c.idCategory === subCategoryOf);
      if (cat) setSub(cat);
    }
  }, [subCategoryOf, categories]);

  const handleClose = () => {
    if (!loading) {
      setName('');
      setSub(null);
      setErrors({ name: false, icon: false });
      onClose();
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const errs = { name: false, icon: false };

    if (!name) errs.name = 'A categoria precisa ter um nome';
    else if (existName(category?.idCategory, name, categories)) {
      errs.name = 'Já existe uma categoria com este nome';
    }

    setErrors(errs);

    if (errs.name || errs.icon) {
      setLoading(false);
      return;
    }

    const data = { name, subCategoryOf: sub?.idCategory || null };

    let postUrl = 'https://us-central1-clube-do-leilao.cloudfunctions.net/categories-store';
    if (category) {
      data.idCategory = category.idCategory;
      postUrl = 'https://us-central1-clube-do-leilao.cloudfunctions.net/categories-update';
    }

    await axios.post(postUrl, data);
    setLoading(false);
    handleClose();
  };

  return (
    <Drawer
      anchor="right"
      open={!!open}
      onClose={handleClose}
      PaperProps={{ sx: { backgroundImage: 'none' } }}
    >
      <Grid container direction="column" sx={{ width: '500px', padding: 4 }}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          wrap="nowrap"
        >
          <Typography fontWeight={800} fontSize={20}>
            {category ? `Editar ${category.name}` : 'Adicionar categoria' }
          </Typography>
          <IconButton onClick={handleClose}>
            <X />
          </IconButton>
        </Grid>
        <form onSubmit={submit}>
          <Grid container direction="column" spacing={4} sx={{ flexGrow: 1, padding: '32px 0' }}>
            {sub && (
              <Grid item>
                <TextField
                  fullWidth
                  select
                  label="Subcategoria de"
                  value={sub.idCategory}
                  disabled
                  color="secondary"
                >
                  <MenuItem value={sub.idCategory}>
                    <Grid container alignItems="center">
                      <img
                        src={sub.icon}
                        alt={sub.name}
                        style={{ marginRight: 16, width: 18, height: 18 }}
                      />
                      {sub.name}
                    </Grid>
                  </MenuItem>
                </TextField>
              </Grid>
            )}
            <Grid item>
              <TextField
                error={errors.name}
                fullWidth
                label="Nome da categoria"
                value={name}
                onChange={(e) => setName(e.target.value)}
                color="secondary"
              />
              {errors.name && (
                <FormHelperText error>{errors.name}</FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                disabled={loading}
                size="large"
                variant="outlined"
                color="secondary"
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                type="submit"
                loading={loading}
                size="large"
                variant="contained"
                color="primary"
              >
                {category ? 'Salvar' : 'Adicionar'}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Drawer>
  );
}

FormCategory.propTypes = {
  category: PropTypes.shape({
    idCategory: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    icon: PropTypes.string,
    subCategoryOf: PropTypes.number,
  }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      idCategory: PropTypes.number.isRequired,
      name: PropTypes.string,
    }),
  ).isRequired,
  subCategoryOf: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

FormCategory.defaultProps = {
  category: null,
  subCategoryOf: null,
};

export default FormCategory;
