import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { Grid, Typography } from '@mui/material';

import { format } from 'date-fns';
import chartsData from './fake-data/charts';

import style from './style';

function AuctionChart() {
  const [days, setDays] = useState([]);
  const [pageviews, setPageviews] = useState([]);
  const [linkAccess, setLinkAccess] = useState([]);

  useEffect(() => {
    setDays(chartsData.map((day) => format(new Date(day.date), 'dd MMM')));
    setPageviews(chartsData.map((day) => day.data.auctions.pageviews));
    setLinkAccess(chartsData.map((day) => day.data.auctions.linkAccess));
  }, []);

  const option = {
    color: ['#ffdc60', '#91cc76'],
    tooltip: {
      trigger: 'axis',
    },
    grid: {
      top: '3%',
      left: '0%',
      right: '3%',
      bottom: '0%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: days,
        axisLine: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        show: true,
        type: 'value',
        axisLabel: {
          show: true,
        },
        splitLine: {
          lineStyle: {
            opacity: 0.05,
          },
        },
      },
    ],
    series: [
      {
        name: 'Acessos à página do leilão',
        type: 'line',
        smooth: true,
        lineStyle: {
          width: 3,
        },
        emphasis: {
          focus: 'series',
        },
        data: pageviews,
      },
      {
        name: 'Acessos ao site do leilão',
        type: 'line',
        smooth: true,
        lineStyle: {
          width: 3,
        },
        emphasis: {
          focus: 'series',
        },
        data: linkAccess,
      },
    ],
  };

  return (
    <Grid item sx={style.card}>
      <Grid container direction="column">
        <Typography sx={style.title}>ACESSOS AOS LEILÕES</Typography>
        <ReactECharts option={option} notMerge lazyUpdate />
        <Grid container justifyContent="center" alignItems="center" sx={{ mt: 4 }}>
          <Grid item>
            <Grid container wrap="nowrap" alignItems="center">
              <div style={style.subtitleColor('#ffdc60')} />
              <Typography sx={style.subtitleText}>Acessos à página do leilão</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container wrap="nowrap" alignItems="center">
              <div style={style.subtitleColor('#91cc76')} />
              <Typography sx={style.subtitleText}>Acessos ao site do leilão</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AuctionChart;
